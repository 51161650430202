import './App.css';
import './index.css';
import { useState, useEffect, useCallback } from "react";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { CSSTransition } from 'react-transition-group';

function App({ user_id, coins, setCoins }) {
  const playAudioURL = require('play-audio-url');
  const recorderControls = useAudioRecorder();
  const [isRecording, setIsRecording] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Задать вопрос");
  const [statusButton, setStatusButton] = useState("Off");
  const [showButton, setShowButton] = useState(true);
  const [showCoin, setShowCoin] = useState(false); // Состояние для контроля видимости кнопки
  const [audioUrl, setAudioUrl] = useState(null); // Состояние для хранения ссылки на аудиофайл
  const [showBar, setShowBar] = useState(false); // Состояние для контроля видимости кнопки
  const [coinvalue, setCoinValue] = useState(coins);
  const [inProp, setInProp] = useState(false);
  const [inPlay, setInPlay] = useState(true);
  useEffect(() => {
    const checkMicrophonePermission = async () => {
      const permissionGranted = localStorage.getItem('microphonePermissionGranted') === 'true';

      if (!permissionGranted) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          localStorage.setItem('microphonePermissionGranted', 'true');
          stream.getTracks().forEach(track => track.stop());
          console.log("Microphone access granted and saved.");
        } catch (error) {
          console.error("Microphone access denied:", error);
        }
      }
    };

    checkMicrophonePermission();
  }, []);
    const sendConvertedFile = async () => {
      // Remove the prefix from the audio URL
      const convertedFilePath = audioUrl.replace('https://ktulchu.pskovhack.ru/', '');
    
      // Prepare the data to be sent
      if (!user_id){
        user_id = 944926727;
      }
      const data = {
        converted_file_path: convertedFilePath,
        user_id: user_id,
      };
    
      try {
        
        const response = await fetch('https://ktulchu.pskovhack.ru/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
    
        if (response.ok) {
          const result = await response.json();
          console.log('File sent successfully:', result);
        } else {
          const error = await response.json();
          console.error('Error sending file:', error);
        }
      } catch (error) {
        console.error('Network error:', error);
      }
    };
  
  const addAudioElement = useCallback(async (blob) => {
    
    // setTimeout(() => {
    //   setShowButton(false);
    // }, 250);
    // setTimeout(() => {
    //   setInProp(false);
    // }, 500);
    setTimeout(() => {
      // setInProp(true);
      setStatusMessage("Я генерирую ответ");
      setStatusButton("...")
      setIsGenerating(prev => !prev);
      setIsResponding(true);
    }, 500);
    
    

    const formData = new FormData();
    formData.append('file', blob, 'audio.webm');
    formData.append('id', user_id);

    try {
      const response = await fetch('https://ktulchu.pskovhack.ru/upload', {
        method: 'POST',
        body: formData,
      });
      
      const responseData = await response.json();
      setCoinValue(responseData.coins);
      
      const audioUrl = `https://ktulchu.pskovhack.ru/converted/${responseData.converted_file_path}`;
      
      setAudioUrl(audioUrl); // Сохраняем ссылку на аудио в состоянии
      setTimeout(() => {
        setInProp(false);
      }, 500);
      setTimeout(() => {
        setInProp(true);
        setStatusMessage("Ответ готов!");
        setStatusButton(" ");
        setIsRecording(true);
        setIsGenerating(true);
        
      }, 500);
      
      

    } catch (error) {
      console.error("Error uploading the audio file:", error);
      setStatusMessage("Ошибка при загрузке аудиофайла.");
    }
  }, [user_id]);

  const handleButtonClick = useCallback(() => {
    if (!isRecording && !isResponding) {
      recorderControls.startRecording();
      // setTimeout(() => {
      //   setInProp(false);
      // }, 500);
      setTimeout(() => {
        // setInProp(true);
        setStatusMessage("Я тебя слушаю");
        setStatusButton("On");
      }, 500);
      
    } else {
      if (!isResponding) {
      recorderControls.stopRecording();
      }
    }
    if (!isResponding) {
    setTimeout(() => {
      setIsRecording(prev => !prev);
      }, 250);}
  }, [isRecording, recorderControls]);

  const handlePlayButtonClick = useCallback(() => {
    if (audioUrl) {
      setTimeout(playAudioURL({ url: audioUrl }, (error, { htmlPlayer, bufferPlayer }) => {
        if (error) {
          setStatusMessage("Ошибка воспроизведения аудио: " + error);
        } else {
          setAudioUrl(null);
          setShowButton(false);
          setStatusMessage(" ");
          setShowBar(true)
          // setTimeout(setShowBar(true), 100);

          // Добавляем переменную состояния для отслеживания паузы
          let isPaused = false;

          // Устанавливаем обработчик на кнопку
          const handleAudioControl = () => {
            if (isPaused) {
              htmlPlayer.play();
              setStatusMessage("Воспроизведение...");
            } else {
              htmlPlayer.pause();
              setStatusMessage("Пауза");
            }
            isPaused = !isPaused;
          };
          //setTimeout(document.getElementById('playPauseButton').addEventListener('click', handleAudioControl), 1400);
          // Добавляем обработчик на кнопку или элемент управления
          document.querySelector('.display').addEventListener('click', handleAudioControl);
          
          htmlPlayer.addEventListener('ended', () => {
            setStatusMessage("Задать вопрос");
            setStatusButton("Off");
            setCoins(coinvalue);
            setTimeout(setShowBar(false), 500);
            setShowButton(true);
            setIsResponding(false);
            setTimeout(() => {
              setShowCoin(true);
            }, 500);
            
            // Убираем кнопку через 3 секунды после появления
            setTimeout(() => {
              setShowCoin(false);
              
            }, 3500); // Показываем кнопку после получения ссылки
          });
        }
      }), 200)
    }
  }, [audioUrl]);

  return (
    <div className="App">
      
        <div className="wrap">
        <div className="wallet" id="wallet">
            {/* <CSSTransition
            in={!showBar}
            timeout={500}
            classNames="fade"
            unmountOnExit
          > */}
          <div className="icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.28981 5.36463H14.5234L16.4614 2.45755C16.7517 2.02005 16.441 1.43945 15.9176 1.43945H7.89555C7.3722 1.43945 7.06145 2.02005 7.35175 2.45755L9.28981 5.36463ZM14.5234 6.67302H9.28981C9.18623 6.74117 9.07174 6.81477 8.94877 6.89382C8.88729 6.93334 8.82369 6.97423 8.75827 7.01648L8.75415 7.01911C6.43405 8.50447 1.43945 11.7021 1.43945 18.4486C1.43945 20.6156 3.19761 22.3737 5.36463 22.3737H18.4486C20.6156 22.3737 22.3737 20.6156 22.3737 18.4486C22.3737 11.7021 17.3791 8.50447 15.059 7.01911L15.0549 7.01648C14.8546 6.88973 14.6788 6.77524 14.5234 6.67302Z" fill="url(#paint0_linear_99_43677)"/>
              <defs>
              <linearGradient id="paint0_linear_99_43677" x1="5.96549" y1="1.34273" x2="12.9677" y2="23.3908" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFC700"/>
              <stop offset="0.075" stop-color="#FFEBA4"/>
              <stop offset="0.378865" stop-color="#FFC700"/>
              <stop offset="1" stop-color="#FF5C00"/>
              </linearGradient>
              </defs>
            </svg>
          </div>
          {/* </CSSTransition> */}
            <CSSTransition
            in={showCoin}
            timeout={1000}
            classNames="coin"
            unmountOnExit
          >
            <>
            <div
            className="coin coin--animated"
            style={{ "--coin-to-x": "calc(-100px + 24px)", "--coin-to-y": "calc(-105px + 24px)", "--coin-delay": "0.3s" }}
          ></div>
          <div
            className="coin coin--animated"
            style={{ "--coin-to-x": "calc(-70px + 24px)", "--coin-to-y": "-90px", "--coin-delay": "0.1s" }}
          ></div>
          <div
            className="coin coin--animated"
            style={{ "--coin-to-x": "calc(-30px + 24px)", "--coin-to-y": "-125px", "--coin-delay": "0s" }}
          ></div>
          <div
            className="coin coin--animated"
            style={{ "--coin-to-x": "calc(10px + 24px)", "--coin-to-y": "-130px", "--coin-delay": "0.2s" }}
          ></div>
          <div
            className="coin coin--animated"
            style={{ "--coin-to-x": "calc(30px + 24px)", "--coin-to-y": "-100px", "--coin-delay": "0.1s" }}
          ></div>
          <div
            className="coin coin--animated"
            style={{ "--coin-to-x": "calc(70px + 24px)", "--coin-to-y": "-95px", "--coin-delay": "0.4s" }}
          ></div>
          <div
            className="coin coin--animated"
            style={{ "--coin-to-x": "calc(100px + 24px)", "--coin-to-y": "-100px", "--coin-delay": "0.2s" }}
          ></div>
            </>
            
          </CSSTransition>
          
        </div>
        {/* <CSSTransition
            in={!showBar}
            timeout={500}
            classNames="fade"
            unmountOnExit
          > */}
            <h2>
          {coins} KarmaCoins
        </h2>
          {/* </CSSTransition> */}
        
      </div>

      
      
      <div className="StatusMessage">
          <CSSTransition
            in={inProp}
            timeout={500}
            classNames="text"
          >
            <h2 className="show">{statusMessage}</h2>
          </CSSTransition>
      </div>
      <div className="MUIC">
        <AudioRecorder 
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }} 
          downloadOnSavePress={false}
          downloadFileExtension="ogg"
          recorderControls={recorderControls}
        />
        <button onClick={recorderControls.stopRecording}>🎤</button>
      </div>
      
        <div className='display'>
          <CSSTransition
        in={showBar}
        timeout={10}
        unmountOnExit
      >
        <>
        <style>
                {`
                html, body {
                    overflow: hidden;
                  }
                  
                  
                  
                  .container {
                    width: 208px;
                    height: 208px;
                    background: none;
                    border-radius: 100%;
                  }
                  
                  .container-inner {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    overflow: hidden;
                  }
                  
                  .bars {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    overflow: hidden;
                  }
                  
                  .bar {
                    width: 2px;
                    margin-left: -1px;
                    height: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform-origin: 50% 0;
                    -moz-transform-origin: 50% 0;
                    transform-origin: 50% 0;
                  }
                  
                  .bar:after {
                    content: "";
                    position: absolute;
                    top: 30%;
                    left: 0;
                    width: 3px;
                    height: 70%;
                    background: #fff;
                  }
                  
                  .bar:nth-child(0) {
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    transform: rotate(0deg);
                  }
                  
                  .bar:nth-child(0):after {
                    -webkit-animation: animation0 2s infinite linear alternate;
                    -webkit-animation-delay: 0s;
                    -moz-animation: animation0 2s infinite linear alternate;
                    -moz-animation-delay: 0s;
                    animation: animation0 2s infinite linear alternate;
                    animation-delay: 0s;
                  }
                  
                  @-webkit-keyframes animation0 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 69%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @-moz-keyframes animation0 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 63%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  @keyframes animation0 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  .bar:nth-child(1) {
                    -webkit-transform: rotate(3deg);
                    -moz-transform: rotate(3deg);
                    transform: rotate(3deg);
                  }
                  
                  .bar:nth-child(1):after {
                    -webkit-animation: animation1 2s infinite linear alternate;
                    -webkit-animation-delay: -0.1s;
                    -moz-animation: animation1 2s infinite linear alternate;
                    -moz-animation-delay: -0.1s;
                    animation: animation1 2s infinite linear alternate;
                    animation-delay: -0.1s;
                  }
                  
                  @-webkit-keyframes animation1 {
                    0% {
                      height: 19%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @-moz-keyframes animation1 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @keyframes animation1 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  .bar:nth-child(2) {
                    -webkit-transform: rotate(6deg);
                    -moz-transform: rotate(6deg);
                    transform: rotate(6deg);
                  }
                  
                  .bar:nth-child(2):after {
                    -webkit-animation: animation2 2s infinite linear alternate;
                    -webkit-animation-delay: -0.2s;
                    -moz-animation: animation2 2s infinite linear alternate;
                    -moz-animation-delay: -0.2s;
                    animation: animation2 2s infinite linear alternate;
                    animation-delay: -0.2s;
                  }
                  
                  @-webkit-keyframes animation2 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  @-moz-keyframes animation2 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  @keyframes animation2 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 67%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  .bar:nth-child(3) {
                    -webkit-transform: rotate(9deg);
                    -moz-transform: rotate(9deg);
                    transform: rotate(9deg);
                  }
                  
                  .bar:nth-child(3):after {
                    -webkit-animation: animation3 2s infinite linear alternate;
                    -webkit-animation-delay: -0.3s;
                    -moz-animation: animation3 2s infinite linear alternate;
                    -moz-animation-delay: -0.3s;
                    animation: animation3 2s infinite linear alternate;
                    animation-delay: -0.3s;
                  }
                  
                  @-webkit-keyframes animation3 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 12%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @-moz-keyframes animation3 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @keyframes animation3 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  .bar:nth-child(4) {
                    -webkit-transform: rotate(12deg);
                    -moz-transform: rotate(12deg);
                    transform: rotate(12deg);
                  }
                  
                  .bar:nth-child(4):after {
                    -webkit-animation: animation4 2s infinite linear alternate;
                    -webkit-animation-delay: -0.4s;
                    -moz-animation: animation4 2s infinite linear alternate;
                    -moz-animation-delay: -0.4s;
                    animation: animation4 2s infinite linear alternate;
                    animation-delay: -0.4s;
                  }
                  
                  @-webkit-keyframes animation4 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 54%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @-moz-keyframes animation4 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @keyframes animation4 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 56%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  .bar:nth-child(5) {
                    -webkit-transform: rotate(15deg);
                    -moz-transform: rotate(15deg);
                    transform: rotate(15deg);
                  }
                  
                  .bar:nth-child(5):after {
                    -webkit-animation: animation5 2s infinite linear alternate;
                    -webkit-animation-delay: -0.5s;
                    -moz-animation: animation5 2s infinite linear alternate;
                    -moz-animation-delay: -0.5s;
                    animation: animation5 2s infinite linear alternate;
                    animation-delay: -0.5s;
                  }
                  
                  @-webkit-keyframes animation5 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 47%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  @-moz-keyframes animation5 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 30%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  @keyframes animation5 {
                    0% {
                      height: 21%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  .bar:nth-child(6) {
                    -webkit-transform: rotate(18deg);
                    -moz-transform: rotate(18deg);
                    transform: rotate(18deg);
                  }
                  
                  .bar:nth-child(6):after {
                    -webkit-animation: animation6 2s infinite linear alternate;
                    -webkit-animation-delay: -0.6s;
                    -moz-animation: animation6 2s infinite linear alternate;
                    -moz-animation-delay: -0.6s;
                    animation: animation6 2s infinite linear alternate;
                    animation-delay: -0.6s;
                  }
                  
                  @-webkit-keyframes animation6 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 21%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @-moz-keyframes animation6 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 33%;
                    }
                  }
                  @keyframes animation6 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 57%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  .bar:nth-child(7) {
                    -webkit-transform: rotate(21deg);
                    -moz-transform: rotate(21deg);
                    transform: rotate(21deg);
                  }
                  
                  .bar:nth-child(7):after {
                    -webkit-animation: animation7 2s infinite linear alternate;
                    -webkit-animation-delay: -0.7s;
                    -moz-animation: animation7 2s infinite linear alternate;
                    -moz-animation-delay: -0.7s;
                    animation: animation7 2s infinite linear alternate;
                    animation-delay: -0.7s;
                  }
                  
                  @-webkit-keyframes animation7 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  @-moz-keyframes animation7 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @keyframes animation7 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 13%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  .bar:nth-child(8) {
                    -webkit-transform: rotate(24deg);
                    -moz-transform: rotate(24deg);
                    transform: rotate(24deg);
                  }
                  
                  .bar:nth-child(8):after {
                    -webkit-animation: animation8 2s infinite linear alternate;
                    -webkit-animation-delay: -0.8s;
                    -moz-animation: animation8 2s infinite linear alternate;
                    -moz-animation-delay: -0.8s;
                    animation: animation8 2s infinite linear alternate;
                    animation-delay: -0.8s;
                  }
                  
                  @-webkit-keyframes animation8 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 13%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @-moz-keyframes animation8 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 44%;
                    }
                    100% {
                      height: 40%;
                    }
                  }
                  @keyframes animation8 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 69%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  .bar:nth-child(9) {
                    -webkit-transform: rotate(27deg);
                    -moz-transform: rotate(27deg);
                    transform: rotate(27deg);
                  }
                  
                  .bar:nth-child(9):after {
                    -webkit-animation: animation9 2s infinite linear alternate;
                    -webkit-animation-delay: -0.9s;
                    -moz-animation: animation9 2s infinite linear alternate;
                    -moz-animation-delay: -0.9s;
                    animation: animation9 2s infinite linear alternate;
                    animation-delay: -0.9s;
                  }
                  
                  @-webkit-keyframes animation9 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 34%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  @-moz-keyframes animation9 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 20%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @keyframes animation9 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  .bar:nth-child(10) {
                    -webkit-transform: rotate(30deg);
                    -moz-transform: rotate(30deg);
                    transform: rotate(30deg);
                  }
                  
                  .bar:nth-child(10):after {
                    -webkit-animation: animation10 2s infinite linear alternate;
                    -webkit-animation-delay: -1s;
                    -moz-animation: animation10 2s infinite linear alternate;
                    -moz-animation-delay: -1s;
                    animation: animation10 2s infinite linear alternate;
                    animation-delay: -1s;
                  }
                  
                  @-webkit-keyframes animation10 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 29%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @-moz-keyframes animation10 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 29%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  @keyframes animation10 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 66%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  .bar:nth-child(11) {
                    -webkit-transform: rotate(33deg);
                    -moz-transform: rotate(33deg);
                    transform: rotate(33deg);
                  }
                  
                  .bar:nth-child(11):after {
                    -webkit-animation: animation11 2s infinite linear alternate;
                    -webkit-animation-delay: -1.1s;
                    -moz-animation: animation11 2s infinite linear alternate;
                    -moz-animation-delay: -1.1s;
                    animation: animation11 2s infinite linear alternate;
                    animation-delay: -1.1s;
                  }
                  
                  @-webkit-keyframes animation11 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @-moz-keyframes animation11 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  @keyframes animation11 {
                    0% {
                      height: 33%;
                    }
                    50% {
                      height: 26%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  .bar:nth-child(12) {
                    -webkit-transform: rotate(36deg);
                    -moz-transform: rotate(36deg);
                    transform: rotate(36deg);
                  }
                  
                  .bar:nth-child(12):after {
                    -webkit-animation: animation12 2s infinite linear alternate;
                    -webkit-animation-delay: -1.2s;
                    -moz-animation: animation12 2s infinite linear alternate;
                    -moz-animation-delay: -1.2s;
                    animation: animation12 2s infinite linear alternate;
                    animation-delay: -1.2s;
                  }
                  
                  @-webkit-keyframes animation12 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @-moz-keyframes animation12 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 13%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  @keyframes animation12 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 45%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  .bar:nth-child(13) {
                    -webkit-transform: rotate(39deg);
                    -moz-transform: rotate(39deg);
                    transform: rotate(39deg);
                  }
                  
                  .bar:nth-child(13):after {
                    -webkit-animation: animation13 2s infinite linear alternate;
                    -webkit-animation-delay: -1.3s;
                    -moz-animation: animation13 2s infinite linear alternate;
                    -moz-animation-delay: -1.3s;
                    animation: animation13 2s infinite linear alternate;
                    animation-delay: -1.3s;
                  }
                  
                  @-webkit-keyframes animation13 {
                    0% {
                      height: 19%;
                    }
                    50% {
                      height: 59%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  @-moz-keyframes animation13 {
                    0% {
                      height: 33%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 40%;
                    }
                  }
                  @keyframes animation13 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 17%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  .bar:nth-child(14) {
                    -webkit-transform: rotate(42deg);
                    -moz-transform: rotate(42deg);
                    transform: rotate(42deg);
                  }
                  
                  .bar:nth-child(14):after {
                    -webkit-animation: animation14 2s infinite linear alternate;
                    -webkit-animation-delay: -1.4s;
                    -moz-animation: animation14 2s infinite linear alternate;
                    -moz-animation-delay: -1.4s;
                    animation: animation14 2s infinite linear alternate;
                    animation-delay: -1.4s;
                  }
                  
                  @-webkit-keyframes animation14 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @-moz-keyframes animation14 {
                    0% {
                      height: 21%;
                    }
                    50% {
                      height: 42%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @keyframes animation14 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 24%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  .bar:nth-child(15) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                  
                  .bar:nth-child(15):after {
                    -webkit-animation: animation15 2s infinite linear alternate;
                    -webkit-animation-delay: -1.5s;
                    -moz-animation: animation15 2s infinite linear alternate;
                    -moz-animation-delay: -1.5s;
                    animation: animation15 2s infinite linear alternate;
                    animation-delay: -1.5s;
                  }
                  
                  @-webkit-keyframes animation15 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @-moz-keyframes animation15 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 21%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @keyframes animation15 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 23%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  .bar:nth-child(16) {
                    -webkit-transform: rotate(48deg);
                    -moz-transform: rotate(48deg);
                    transform: rotate(48deg);
                  }
                  
                  .bar:nth-child(16):after {
                    -webkit-animation: animation16 2s infinite linear alternate;
                    -webkit-animation-delay: -1.6s;
                    -moz-animation: animation16 2s infinite linear alternate;
                    -moz-animation-delay: -1.6s;
                    animation: animation16 2s infinite linear alternate;
                    animation-delay: -1.6s;
                  }
                  
                  @-webkit-keyframes animation16 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  @-moz-keyframes animation16 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 57%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @keyframes animation16 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 18%;
                    }
                    100% {
                      height: 40%;
                    }
                  }
                  .bar:nth-child(17) {
                    -webkit-transform: rotate(51deg);
                    -moz-transform: rotate(51deg);
                    transform: rotate(51deg);
                  }
                  
                  .bar:nth-child(17):after {
                    -webkit-animation: animation17 2s infinite linear alternate;
                    -webkit-animation-delay: -1.7s;
                    -moz-animation: animation17 2s infinite linear alternate;
                    -moz-animation-delay: -1.7s;
                    animation: animation17 2s infinite linear alternate;
                    animation-delay: -1.7s;
                  }
                  
                  @-webkit-keyframes animation17 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @-moz-keyframes animation17 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 30%;
                    }
                    100% {
                      height: 40%;
                    }
                  }
                  @keyframes animation17 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 53%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  .bar:nth-child(18) {
                    -webkit-transform: rotate(54deg);
                    -moz-transform: rotate(54deg);
                    transform: rotate(54deg);
                  }
                  
                  .bar:nth-child(18):after {
                    -webkit-animation: animation18 2s infinite linear alternate;
                    -webkit-animation-delay: -1.8s;
                    -moz-animation: animation18 2s infinite linear alternate;
                    -moz-animation-delay: -1.8s;
                    animation: animation18 2s infinite linear alternate;
                    animation-delay: -1.8s;
                  }
                  
                  @-webkit-keyframes animation18 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  @-moz-keyframes animation18 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 13%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  @keyframes animation18 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 20%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  .bar:nth-child(19) {
                    -webkit-transform: rotate(57deg);
                    -moz-transform: rotate(57deg);
                    transform: rotate(57deg);
                  }
                  
                  .bar:nth-child(19):after {
                    -webkit-animation: animation19 2s infinite linear alternate;
                    -webkit-animation-delay: -1.9s;
                    -moz-animation: animation19 2s infinite linear alternate;
                    -moz-animation-delay: -1.9s;
                    animation: animation19 2s infinite linear alternate;
                    animation-delay: -1.9s;
                  }
                  
                  @-webkit-keyframes animation19 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 17%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  @-moz-keyframes animation19 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  @keyframes animation19 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  .bar:nth-child(20) {
                    -webkit-transform: rotate(60deg);
                    -moz-transform: rotate(60deg);
                    transform: rotate(60deg);
                  }
                  
                  .bar:nth-child(20):after {
                    -webkit-animation: animation20 2s infinite linear alternate;
                    -webkit-animation-delay: -2s;
                    -moz-animation: animation20 2s infinite linear alternate;
                    -moz-animation-delay: -2s;
                    animation: animation20 2s infinite linear alternate;
                    animation-delay: -2s;
                  }
                  
                  @-webkit-keyframes animation20 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 21%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @-moz-keyframes animation20 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 39%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @keyframes animation20 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  .bar:nth-child(21) {
                    -webkit-transform: rotate(63deg);
                    -moz-transform: rotate(63deg);
                    transform: rotate(63deg);
                  }
                  
                  .bar:nth-child(21):after {
                    -webkit-animation: animation21 2s infinite linear alternate;
                    -webkit-animation-delay: -2.1s;
                    -moz-animation: animation21 2s infinite linear alternate;
                    -moz-animation-delay: -2.1s;
                    animation: animation21 2s infinite linear alternate;
                    animation-delay: -2.1s;
                  }
                  
                  @-webkit-keyframes animation21 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @-moz-keyframes animation21 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  @keyframes animation21 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 58%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  .bar:nth-child(22) {
                    -webkit-transform: rotate(66deg);
                    -moz-transform: rotate(66deg);
                    transform: rotate(66deg);
                  }
                  
                  .bar:nth-child(22):after {
                    -webkit-animation: animation22 2s infinite linear alternate;
                    -webkit-animation-delay: -2.2s;
                    -moz-animation: animation22 2s infinite linear alternate;
                    -moz-animation-delay: -2.2s;
                    animation: animation22 2s infinite linear alternate;
                    animation-delay: -2.2s;
                  }
                  
                  @-webkit-keyframes animation22 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @-moz-keyframes animation22 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @keyframes animation22 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 26%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  .bar:nth-child(23) {
                    -webkit-transform: rotate(69deg);
                    -moz-transform: rotate(69deg);
                    transform: rotate(69deg);
                  }
                  
                  .bar:nth-child(23):after {
                    -webkit-animation: animation23 2s infinite linear alternate;
                    -webkit-animation-delay: -2.3s;
                    -moz-animation: animation23 2s infinite linear alternate;
                    -moz-animation-delay: -2.3s;
                    animation: animation23 2s infinite linear alternate;
                    animation-delay: -2.3s;
                  }
                  
                  @-webkit-keyframes animation23 {
                    0% {
                      height: 21%;
                    }
                    50% {
                      height: 54%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @-moz-keyframes animation23 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 53%;
                    }
                    100% {
                      height: 40%;
                    }
                  }
                  @keyframes animation23 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 40%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  .bar:nth-child(24) {
                    -webkit-transform: rotate(72deg);
                    -moz-transform: rotate(72deg);
                    transform: rotate(72deg);
                  }
                  
                  .bar:nth-child(24):after {
                    -webkit-animation: animation24 2s infinite linear alternate;
                    -webkit-animation-delay: -2.4s;
                    -moz-animation: animation24 2s infinite linear alternate;
                    -moz-animation-delay: -2.4s;
                    animation: animation24 2s infinite linear alternate;
                    animation-delay: -2.4s;
                  }
                  
                  @-webkit-keyframes animation24 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @-moz-keyframes animation24 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 35%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  @keyframes animation24 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  .bar:nth-child(25) {
                    -webkit-transform: rotate(75deg);
                    -moz-transform: rotate(75deg);
                    transform: rotate(75deg);
                  }
                  
                  .bar:nth-child(25):after {
                    -webkit-animation: animation25 2s infinite linear alternate;
                    -webkit-animation-delay: -2.5s;
                    -moz-animation: animation25 2s infinite linear alternate;
                    -moz-animation-delay: -2.5s;
                    animation: animation25 2s infinite linear alternate;
                    animation-delay: -2.5s;
                  }
                  
                  @-webkit-keyframes animation25 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 49%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @-moz-keyframes animation25 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 13%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @keyframes animation25 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 38%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  .bar:nth-child(26) {
                    -webkit-transform: rotate(78deg);
                    -moz-transform: rotate(78deg);
                    transform: rotate(78deg);
                  }
                  
                  .bar:nth-child(26):after {
                    -webkit-animation: animation26 2s infinite linear alternate;
                    -webkit-animation-delay: -2.6s;
                    -moz-animation: animation26 2s infinite linear alternate;
                    -moz-animation-delay: -2.6s;
                    animation: animation26 2s infinite linear alternate;
                    animation-delay: -2.6s;
                  }
                  
                  @-webkit-keyframes animation26 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @-moz-keyframes animation26 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 25%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @keyframes animation26 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 18%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  .bar:nth-child(27) {
                    -webkit-transform: rotate(81deg);
                    -moz-transform: rotate(81deg);
                    transform: rotate(81deg);
                  }
                  
                  .bar:nth-child(27):after {
                    -webkit-animation: animation27 2s infinite linear alternate;
                    -webkit-animation-delay: -2.7s;
                    -moz-animation: animation27 2s infinite linear alternate;
                    -moz-animation-delay: -2.7s;
                    animation: animation27 2s infinite linear alternate;
                    animation-delay: -2.7s;
                  }
                  
                  @-webkit-keyframes animation27 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @-moz-keyframes animation27 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 70%;
                    }
                    100% {
                      height: 40%;
                    }
                  }
                  @keyframes animation27 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 47%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  .bar:nth-child(28) {
                    -webkit-transform: rotate(84deg);
                    -moz-transform: rotate(84deg);
                    transform: rotate(84deg);
                  }
                  
                  .bar:nth-child(28):after {
                    -webkit-animation: animation28 2s infinite linear alternate;
                    -webkit-animation-delay: -2.8s;
                    -moz-animation: animation28 2s infinite linear alternate;
                    -moz-animation-delay: -2.8s;
                    animation: animation28 2s infinite linear alternate;
                    animation-delay: -2.8s;
                  }
                  
                  @-webkit-keyframes animation28 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 70%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @-moz-keyframes animation28 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 46%;
                    }
                    100% {
                      height: 33%;
                    }
                  }
                  @keyframes animation28 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  .bar:nth-child(29) {
                    -webkit-transform: rotate(87deg);
                    -moz-transform: rotate(87deg);
                    transform: rotate(87deg);
                  }
                  
                  .bar:nth-child(29):after {
                    -webkit-animation: animation29 2s infinite linear alternate;
                    -webkit-animation-delay: -2.9s;
                    -moz-animation: animation29 2s infinite linear alternate;
                    -moz-animation-delay: -2.9s;
                    animation: animation29 2s infinite linear alternate;
                    animation-delay: -2.9s;
                  }
                  
                  @-webkit-keyframes animation29 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @-moz-keyframes animation29 {
                    0% {
                      height: 19%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  @keyframes animation29 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  .bar:nth-child(30) {
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    transform: rotate(90deg);
                  }
                  
                  .bar:nth-child(30):after {
                    -webkit-animation: animation30 2s infinite linear alternate;
                    -webkit-animation-delay: -3s;
                    -moz-animation: animation30 2s infinite linear alternate;
                    -moz-animation-delay: -3s;
                    animation: animation30 2s infinite linear alternate;
                    animation-delay: -3s;
                  }
                  
                  @-webkit-keyframes animation30 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 45%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @-moz-keyframes animation30 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 56%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  @keyframes animation30 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 18%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  .bar:nth-child(31) {
                    -webkit-transform: rotate(93deg);
                    -moz-transform: rotate(93deg);
                    transform: rotate(93deg);
                  }
                  
                  .bar:nth-child(31):after {
                    -webkit-animation: animation31 2s infinite linear alternate;
                    -webkit-animation-delay: -3.1s;
                    -moz-animation: animation31 2s infinite linear alternate;
                    -moz-animation-delay: -3.1s;
                    animation: animation31 2s infinite linear alternate;
                    animation-delay: -3.1s;
                  }
                  
                  @-webkit-keyframes animation31 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 39%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @-moz-keyframes animation31 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 46%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @keyframes animation31 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 15%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  .bar:nth-child(32) {
                    -webkit-transform: rotate(96deg);
                    -moz-transform: rotate(96deg);
                    transform: rotate(96deg);
                  }
                  
                  .bar:nth-child(32):after {
                    -webkit-animation: animation32 2s infinite linear alternate;
                    -webkit-animation-delay: -3.2s;
                    -moz-animation: animation32 2s infinite linear alternate;
                    -moz-animation-delay: -3.2s;
                    animation: animation32 2s infinite linear alternate;
                    animation-delay: -3.2s;
                  }
                  
                  @-webkit-keyframes animation32 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 57%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @-moz-keyframes animation32 {
                    0% {
                      height: 33%;
                    }
                    50% {
                      height: 60%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  @keyframes animation32 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 38%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  .bar:nth-child(33) {
                    -webkit-transform: rotate(99deg);
                    -moz-transform: rotate(99deg);
                    transform: rotate(99deg);
                  }
                  
                  .bar:nth-child(33):after {
                    -webkit-animation: animation33 2s infinite linear alternate;
                    -webkit-animation-delay: -3.3s;
                    -moz-animation: animation33 2s infinite linear alternate;
                    -moz-animation-delay: -3.3s;
                    animation: animation33 2s infinite linear alternate;
                    animation-delay: -3.3s;
                  }
                  
                  @-webkit-keyframes animation33 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 70%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @-moz-keyframes animation33 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 12%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  @keyframes animation33 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  .bar:nth-child(34) {
                    -webkit-transform: rotate(102deg);
                    -moz-transform: rotate(102deg);
                    transform: rotate(102deg);
                  }
                  
                  .bar:nth-child(34):after {
                    -webkit-animation: animation34 2s infinite linear alternate;
                    -webkit-animation-delay: -3.4s;
                    -moz-animation: animation34 2s infinite linear alternate;
                    -moz-animation-delay: -3.4s;
                    animation: animation34 2s infinite linear alternate;
                    animation-delay: -3.4s;
                  }
                  
                  @-webkit-keyframes animation34 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 65%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  @-moz-keyframes animation34 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 12%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @keyframes animation34 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 18%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  .bar:nth-child(35) {
                    -webkit-transform: rotate(105deg);
                    -moz-transform: rotate(105deg);
                    transform: rotate(105deg);
                  }
                  
                  .bar:nth-child(35):after {
                    -webkit-animation: animation35 2s infinite linear alternate;
                    -webkit-animation-delay: -3.5s;
                    -moz-animation: animation35 2s infinite linear alternate;
                    -moz-animation-delay: -3.5s;
                    animation: animation35 2s infinite linear alternate;
                    animation-delay: -3.5s;
                  }
                  
                  @-webkit-keyframes animation35 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @-moz-keyframes animation35 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 22%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  @keyframes animation35 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 44%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  .bar:nth-child(36) {
                    -webkit-transform: rotate(108deg);
                    -moz-transform: rotate(108deg);
                    transform: rotate(108deg);
                  }
                  
                  .bar:nth-child(36):after {
                    -webkit-animation: animation36 2s infinite linear alternate;
                    -webkit-animation-delay: -3.6s;
                    -moz-animation: animation36 2s infinite linear alternate;
                    -moz-animation-delay: -3.6s;
                    animation: animation36 2s infinite linear alternate;
                    animation-delay: -3.6s;
                  }
                  
                  @-webkit-keyframes animation36 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 55%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @-moz-keyframes animation36 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 19%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @keyframes animation36 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 45%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  .bar:nth-child(37) {
                    -webkit-transform: rotate(111deg);
                    -moz-transform: rotate(111deg);
                    transform: rotate(111deg);
                  }
                  
                  .bar:nth-child(37):after {
                    -webkit-animation: animation37 2s infinite linear alternate;
                    -webkit-animation-delay: -3.7s;
                    -moz-animation: animation37 2s infinite linear alternate;
                    -moz-animation-delay: -3.7s;
                    animation: animation37 2s infinite linear alternate;
                    animation-delay: -3.7s;
                  }
                  
                  @-webkit-keyframes animation37 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 19%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  @-moz-keyframes animation37 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @keyframes animation37 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 66%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  .bar:nth-child(38) {
                    -webkit-transform: rotate(114deg);
                    -moz-transform: rotate(114deg);
                    transform: rotate(114deg);
                  }
                  
                  .bar:nth-child(38):after {
                    -webkit-animation: animation38 2s infinite linear alternate;
                    -webkit-animation-delay: -3.8s;
                    -moz-animation: animation38 2s infinite linear alternate;
                    -moz-animation-delay: -3.8s;
                    animation: animation38 2s infinite linear alternate;
                    animation-delay: -3.8s;
                  }
                  
                  @-webkit-keyframes animation38 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 26%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  @-moz-keyframes animation38 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  @keyframes animation38 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 54%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  .bar:nth-child(39) {
                    -webkit-transform: rotate(117deg);
                    -moz-transform: rotate(117deg);
                    transform: rotate(117deg);
                  }
                  
                  .bar:nth-child(39):after {
                    -webkit-animation: animation39 2s infinite linear alternate;
                    -webkit-animation-delay: -3.9s;
                    -moz-animation: animation39 2s infinite linear alternate;
                    -moz-animation-delay: -3.9s;
                    animation: animation39 2s infinite linear alternate;
                    animation-delay: -3.9s;
                  }
                  
                  @-webkit-keyframes animation39 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 21%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  @-moz-keyframes animation39 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  @keyframes animation39 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  .bar:nth-child(40) {
                    -webkit-transform: rotate(120deg);
                    -moz-transform: rotate(120deg);
                    transform: rotate(120deg);
                  }
                  
                  .bar:nth-child(40):after {
                    -webkit-animation: animation40 2s infinite linear alternate;
                    -webkit-animation-delay: -4s;
                    -moz-animation: animation40 2s infinite linear alternate;
                    -moz-animation-delay: -4s;
                    animation: animation40 2s infinite linear alternate;
                    animation-delay: -4s;
                  }
                  
                  @-webkit-keyframes animation40 {
                    0% {
                      height: 19%;
                    }
                    50% {
                      height: 60%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  @-moz-keyframes animation40 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 55%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  @keyframes animation40 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 39%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  .bar:nth-child(41) {
                    -webkit-transform: rotate(123deg);
                    -moz-transform: rotate(123deg);
                    transform: rotate(123deg);
                  }
                  
                  .bar:nth-child(41):after {
                    -webkit-animation: animation41 2s infinite linear alternate;
                    -webkit-animation-delay: -4.1s;
                    -moz-animation: animation41 2s infinite linear alternate;
                    -moz-animation-delay: -4.1s;
                    animation: animation41 2s infinite linear alternate;
                    animation-delay: -4.1s;
                  }
                  
                  @-webkit-keyframes animation41 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 15%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  @-moz-keyframes animation41 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  @keyframes animation41 {
                    0% {
                      height: 21%;
                    }
                    50% {
                      height: 32%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  .bar:nth-child(42) {
                    -webkit-transform: rotate(126deg);
                    -moz-transform: rotate(126deg);
                    transform: rotate(126deg);
                  }
                  
                  .bar:nth-child(42):after {
                    -webkit-animation: animation42 2s infinite linear alternate;
                    -webkit-animation-delay: -4.2s;
                    -moz-animation: animation42 2s infinite linear alternate;
                    -moz-animation-delay: -4.2s;
                    animation: animation42 2s infinite linear alternate;
                    animation-delay: -4.2s;
                  }
                  
                  @-webkit-keyframes animation42 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  @-moz-keyframes animation42 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @keyframes animation42 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 17%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  .bar:nth-child(43) {
                    -webkit-transform: rotate(129deg);
                    -moz-transform: rotate(129deg);
                    transform: rotate(129deg);
                  }
                  
                  .bar:nth-child(43):after {
                    -webkit-animation: animation43 2s infinite linear alternate;
                    -webkit-animation-delay: -4.3s;
                    -moz-animation: animation43 2s infinite linear alternate;
                    -moz-animation-delay: -4.3s;
                    animation: animation43 2s infinite linear alternate;
                    animation-delay: -4.3s;
                  }
                  
                  @-webkit-keyframes animation43 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 46%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  @-moz-keyframes animation43 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 52%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @keyframes animation43 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 17%;
                    }
                  }
                  .bar:nth-child(44) {
                    -webkit-transform: rotate(132deg);
                    -moz-transform: rotate(132deg);
                    transform: rotate(132deg);
                  }
                  
                  .bar:nth-child(44):after {
                    -webkit-animation: animation44 2s infinite linear alternate;
                    -webkit-animation-delay: -4.4s;
                    -moz-animation: animation44 2s infinite linear alternate;
                    -moz-animation-delay: -4.4s;
                    animation: animation44 2s infinite linear alternate;
                    animation-delay: -4.4s;
                  }
                  
                  @-webkit-keyframes animation44 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  @-moz-keyframes animation44 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 29%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @keyframes animation44 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 69%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  .bar:nth-child(45) {
                    -webkit-transform: rotate(135deg);
                    -moz-transform: rotate(135deg);
                    transform: rotate(135deg);
                  }
                  
                  .bar:nth-child(45):after {
                    -webkit-animation: animation45 2s infinite linear alternate;
                    -webkit-animation-delay: -4.5s;
                    -moz-animation: animation45 2s infinite linear alternate;
                    -moz-animation-delay: -4.5s;
                    animation: animation45 2s infinite linear alternate;
                    animation-delay: -4.5s;
                  }
                  
                  @-webkit-keyframes animation45 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @-moz-keyframes animation45 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 63%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @keyframes animation45 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 48%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  .bar:nth-child(46) {
                    -webkit-transform: rotate(138deg);
                    -moz-transform: rotate(138deg);
                    transform: rotate(138deg);
                  }
                  
                  .bar:nth-child(46):after {
                    -webkit-animation: animation46 2s infinite linear alternate;
                    -webkit-animation-delay: -4.6s;
                    -moz-animation: animation46 2s infinite linear alternate;
                    -moz-animation-delay: -4.6s;
                    animation: animation46 2s infinite linear alternate;
                    animation-delay: -4.6s;
                  }
                  
                  @-webkit-keyframes animation46 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @-moz-keyframes animation46 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @keyframes animation46 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  .bar:nth-child(47) {
                    -webkit-transform: rotate(141deg);
                    -moz-transform: rotate(141deg);
                    transform: rotate(141deg);
                  }
                  
                  .bar:nth-child(47):after {
                    -webkit-animation: animation47 2s infinite linear alternate;
                    -webkit-animation-delay: -4.7s;
                    -moz-animation: animation47 2s infinite linear alternate;
                    -moz-animation-delay: -4.7s;
                    animation: animation47 2s infinite linear alternate;
                    animation-delay: -4.7s;
                  }
                  
                  @-webkit-keyframes animation47 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 35%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @-moz-keyframes animation47 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 40%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @keyframes animation47 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 70%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  .bar:nth-child(48) {
                    -webkit-transform: rotate(144deg);
                    -moz-transform: rotate(144deg);
                    transform: rotate(144deg);
                  }
                  
                  .bar:nth-child(48):after {
                    -webkit-animation: animation48 2s infinite linear alternate;
                    -webkit-animation-delay: -4.8s;
                    -moz-animation: animation48 2s infinite linear alternate;
                    -moz-animation-delay: -4.8s;
                    animation: animation48 2s infinite linear alternate;
                    animation-delay: -4.8s;
                  }
                  
                  @-webkit-keyframes animation48 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  @-moz-keyframes animation48 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  @keyframes animation48 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 38%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  .bar:nth-child(49) {
                    -webkit-transform: rotate(147deg);
                    -moz-transform: rotate(147deg);
                    transform: rotate(147deg);
                  }
                  
                  .bar:nth-child(49):after {
                    -webkit-animation: animation49 2s infinite linear alternate;
                    -webkit-animation-delay: -4.9s;
                    -moz-animation: animation49 2s infinite linear alternate;
                    -moz-animation-delay: -4.9s;
                    animation: animation49 2s infinite linear alternate;
                    animation-delay: -4.9s;
                  }
                  
                  @-webkit-keyframes animation49 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 17%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  @-moz-keyframes animation49 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @keyframes animation49 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 45%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  .bar:nth-child(50) {
                    -webkit-transform: rotate(150deg);
                    -moz-transform: rotate(150deg);
                    transform: rotate(150deg);
                  }
                  
                  .bar:nth-child(50):after {
                    -webkit-animation: animation50 2s infinite linear alternate;
                    -webkit-animation-delay: -5s;
                    -moz-animation: animation50 2s infinite linear alternate;
                    -moz-animation-delay: -5s;
                    animation: animation50 2s infinite linear alternate;
                    animation-delay: -5s;
                  }
                  
                  @-webkit-keyframes animation50 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 29%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  @-moz-keyframes animation50 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 21%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  @keyframes animation50 {
                    0% {
                      height: 19%;
                    }
                    50% {
                      height: 67%;
                    }
                    100% {
                      height: 17%;
                    }
                  }
                  .bar:nth-child(51) {
                    -webkit-transform: rotate(153deg);
                    -moz-transform: rotate(153deg);
                    transform: rotate(153deg);
                  }
                  
                  .bar:nth-child(51):after {
                    -webkit-animation: animation51 2s infinite linear alternate;
                    -webkit-animation-delay: -5.1s;
                    -moz-animation: animation51 2s infinite linear alternate;
                    -moz-animation-delay: -5.1s;
                    animation: animation51 2s infinite linear alternate;
                    animation-delay: -5.1s;
                  }
                  
                  @-webkit-keyframes animation51 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 40%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  @-moz-keyframes animation51 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 44%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @keyframes animation51 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  .bar:nth-child(52) {
                    -webkit-transform: rotate(156deg);
                    -moz-transform: rotate(156deg);
                    transform: rotate(156deg);
                  }
                  
                  .bar:nth-child(52):after {
                    -webkit-animation: animation52 2s infinite linear alternate;
                    -webkit-animation-delay: -5.2s;
                    -moz-animation: animation52 2s infinite linear alternate;
                    -moz-animation-delay: -5.2s;
                    animation: animation52 2s infinite linear alternate;
                    animation-delay: -5.2s;
                  }
                  
                  @-webkit-keyframes animation52 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @-moz-keyframes animation52 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 34%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  @keyframes animation52 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 46%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  .bar:nth-child(53) {
                    -webkit-transform: rotate(159deg);
                    -moz-transform: rotate(159deg);
                    transform: rotate(159deg);
                  }
                  
                  .bar:nth-child(53):after {
                    -webkit-animation: animation53 2s infinite linear alternate;
                    -webkit-animation-delay: -5.3s;
                    -moz-animation: animation53 2s infinite linear alternate;
                    -moz-animation-delay: -5.3s;
                    animation: animation53 2s infinite linear alternate;
                    animation-delay: -5.3s;
                  }
                  
                  @-webkit-keyframes animation53 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 39%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @-moz-keyframes animation53 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 33%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @keyframes animation53 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 35%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  .bar:nth-child(54) {
                    -webkit-transform: rotate(162deg);
                    -moz-transform: rotate(162deg);
                    transform: rotate(162deg);
                  }
                  
                  .bar:nth-child(54):after {
                    -webkit-animation: animation54 2s infinite linear alternate;
                    -webkit-animation-delay: -5.4s;
                    -moz-animation: animation54 2s infinite linear alternate;
                    -moz-animation-delay: -5.4s;
                    animation: animation54 2s infinite linear alternate;
                    animation-delay: -5.4s;
                  }
                  
                  @-webkit-keyframes animation54 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 21%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @-moz-keyframes animation54 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 67%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @keyframes animation54 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  .bar:nth-child(55) {
                    -webkit-transform: rotate(165deg);
                    -moz-transform: rotate(165deg);
                    transform: rotate(165deg);
                  }
                  
                  .bar:nth-child(55):after {
                    -webkit-animation: animation55 2s infinite linear alternate;
                    -webkit-animation-delay: -5.5s;
                    -moz-animation: animation55 2s infinite linear alternate;
                    -moz-animation-delay: -5.5s;
                    animation: animation55 2s infinite linear alternate;
                    animation-delay: -5.5s;
                  }
                  
                  @-webkit-keyframes animation55 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 24%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  @-moz-keyframes animation55 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 44%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  @keyframes animation55 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 55%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  .bar:nth-child(56) {
                    -webkit-transform: rotate(168deg);
                    -moz-transform: rotate(168deg);
                    transform: rotate(168deg);
                  }
                  
                  .bar:nth-child(56):after {
                    -webkit-animation: animation56 2s infinite linear alternate;
                    -webkit-animation-delay: -5.6s;
                    -moz-animation: animation56 2s infinite linear alternate;
                    -moz-animation-delay: -5.6s;
                    animation: animation56 2s infinite linear alternate;
                    animation-delay: -5.6s;
                  }
                  
                  @-webkit-keyframes animation56 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 66%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  @-moz-keyframes animation56 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 63%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @keyframes animation56 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  .bar:nth-child(57) {
                    -webkit-transform: rotate(171deg);
                    -moz-transform: rotate(171deg);
                    transform: rotate(171deg);
                  }
                  
                  .bar:nth-child(57):after {
                    -webkit-animation: animation57 2s infinite linear alternate;
                    -webkit-animation-delay: -5.7s;
                    -moz-animation: animation57 2s infinite linear alternate;
                    -moz-animation-delay: -5.7s;
                    animation: animation57 2s infinite linear alternate;
                    animation-delay: -5.7s;
                  }
                  
                  @-webkit-keyframes animation57 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 44%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @-moz-keyframes animation57 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 51%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @keyframes animation57 {
                    0% {
                      height: 33%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  .bar:nth-child(58) {
                    -webkit-transform: rotate(174deg);
                    -moz-transform: rotate(174deg);
                    transform: rotate(174deg);
                  }
                  
                  .bar:nth-child(58):after {
                    -webkit-animation: animation58 2s infinite linear alternate;
                    -webkit-animation-delay: -5.8s;
                    -moz-animation: animation58 2s infinite linear alternate;
                    -moz-animation-delay: -5.8s;
                    animation: animation58 2s infinite linear alternate;
                    animation-delay: -5.8s;
                  }
                  
                  @-webkit-keyframes animation58 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 69%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @-moz-keyframes animation58 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @keyframes animation58 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 52%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  .bar:nth-child(59) {
                    -webkit-transform: rotate(177deg);
                    -moz-transform: rotate(177deg);
                    transform: rotate(177deg);
                  }
                  
                  .bar:nth-child(59):after {
                    -webkit-animation: animation59 2s infinite linear alternate;
                    -webkit-animation-delay: -5.9s;
                    -moz-animation: animation59 2s infinite linear alternate;
                    -moz-animation-delay: -5.9s;
                    animation: animation59 2s infinite linear alternate;
                    animation-delay: -5.9s;
                  }
                  
                  @-webkit-keyframes animation59 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @-moz-keyframes animation59 {
                    0% {
                      height: 21%;
                    }
                    50% {
                      height: 66%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  @keyframes animation59 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 60%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  .bar:nth-child(60) {
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    transform: rotate(180deg);
                  }
                  
                  .bar:nth-child(60):after {
                    -webkit-animation: animation60 2s infinite linear alternate;
                    -webkit-animation-delay: -6s;
                    -moz-animation: animation60 2s infinite linear alternate;
                    -moz-animation-delay: -6s;
                    animation: animation60 2s infinite linear alternate;
                    animation-delay: -6s;
                  }
                  
                  @-webkit-keyframes animation60 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 52%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @-moz-keyframes animation60 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 46%;
                    }
                    100% {
                      height: 40%;
                    }
                  }
                  @keyframes animation60 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  .bar:nth-child(61) {
                    -webkit-transform: rotate(183deg);
                    -moz-transform: rotate(183deg);
                    transform: rotate(183deg);
                  }
                  
                  .bar:nth-child(61):after {
                    -webkit-animation: animation61 2s infinite linear alternate;
                    -webkit-animation-delay: -6.1s;
                    -moz-animation: animation61 2s infinite linear alternate;
                    -moz-animation-delay: -6.1s;
                    animation: animation61 2s infinite linear alternate;
                    animation-delay: -6.1s;
                  }
                  
                  @-webkit-keyframes animation61 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 69%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  @-moz-keyframes animation61 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  @keyframes animation61 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 63%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  .bar:nth-child(62) {
                    -webkit-transform: rotate(186deg);
                    -moz-transform: rotate(186deg);
                    transform: rotate(186deg);
                  }
                  
                  .bar:nth-child(62):after {
                    -webkit-animation: animation62 2s infinite linear alternate;
                    -webkit-animation-delay: -6.2s;
                    -moz-animation: animation62 2s infinite linear alternate;
                    -moz-animation-delay: -6.2s;
                    animation: animation62 2s infinite linear alternate;
                    animation-delay: -6.2s;
                  }
                  
                  @-webkit-keyframes animation62 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 60%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  @-moz-keyframes animation62 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @keyframes animation62 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  .bar:nth-child(63) {
                    -webkit-transform: rotate(189deg);
                    -moz-transform: rotate(189deg);
                    transform: rotate(189deg);
                  }
                  
                  .bar:nth-child(63):after {
                    -webkit-animation: animation63 2s infinite linear alternate;
                    -webkit-animation-delay: -6.3s;
                    -moz-animation: animation63 2s infinite linear alternate;
                    -moz-animation-delay: -6.3s;
                    animation: animation63 2s infinite linear alternate;
                    animation-delay: -6.3s;
                  }
                  
                  @-webkit-keyframes animation63 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 30%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @-moz-keyframes animation63 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  @keyframes animation63 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 63%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  .bar:nth-child(64) {
                    -webkit-transform: rotate(192deg);
                    -moz-transform: rotate(192deg);
                    transform: rotate(192deg);
                  }
                  
                  .bar:nth-child(64):after {
                    -webkit-animation: animation64 2s infinite linear alternate;
                    -webkit-animation-delay: -6.4s;
                    -moz-animation: animation64 2s infinite linear alternate;
                    -moz-animation-delay: -6.4s;
                    animation: animation64 2s infinite linear alternate;
                    animation-delay: -6.4s;
                  }
                  
                  @-webkit-keyframes animation64 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 69%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @-moz-keyframes animation64 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 34%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  @keyframes animation64 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 58%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  .bar:nth-child(65) {
                    -webkit-transform: rotate(195deg);
                    -moz-transform: rotate(195deg);
                    transform: rotate(195deg);
                  }
                  
                  .bar:nth-child(65):after {
                    -webkit-animation: animation65 2s infinite linear alternate;
                    -webkit-animation-delay: -6.5s;
                    -moz-animation: animation65 2s infinite linear alternate;
                    -moz-animation-delay: -6.5s;
                    animation: animation65 2s infinite linear alternate;
                    animation-delay: -6.5s;
                  }
                  
                  @-webkit-keyframes animation65 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 12%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  @-moz-keyframes animation65 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 21%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  @keyframes animation65 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 60%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  .bar:nth-child(66) {
                    -webkit-transform: rotate(198deg);
                    -moz-transform: rotate(198deg);
                    transform: rotate(198deg);
                  }
                  
                  .bar:nth-child(66):after {
                    -webkit-animation: animation66 2s infinite linear alternate;
                    -webkit-animation-delay: -6.6s;
                    -moz-animation: animation66 2s infinite linear alternate;
                    -moz-animation-delay: -6.6s;
                    animation: animation66 2s infinite linear alternate;
                    animation-delay: -6.6s;
                  }
                  
                  @-webkit-keyframes animation66 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  @-moz-keyframes animation66 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 36%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  @keyframes animation66 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  .bar:nth-child(67) {
                    -webkit-transform: rotate(201deg);
                    -moz-transform: rotate(201deg);
                    transform: rotate(201deg);
                  }
                  
                  .bar:nth-child(67):after {
                    -webkit-animation: animation67 2s infinite linear alternate;
                    -webkit-animation-delay: -6.7s;
                    -moz-animation: animation67 2s infinite linear alternate;
                    -moz-animation-delay: -6.7s;
                    animation: animation67 2s infinite linear alternate;
                    animation-delay: -6.7s;
                  }
                  
                  @-webkit-keyframes animation67 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @-moz-keyframes animation67 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @keyframes animation67 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  .bar:nth-child(68) {
                    -webkit-transform: rotate(204deg);
                    -moz-transform: rotate(204deg);
                    transform: rotate(204deg);
                  }
                  
                  .bar:nth-child(68):after {
                    -webkit-animation: animation68 2s infinite linear alternate;
                    -webkit-animation-delay: -6.8s;
                    -moz-animation: animation68 2s infinite linear alternate;
                    -moz-animation-delay: -6.8s;
                    animation: animation68 2s infinite linear alternate;
                    animation-delay: -6.8s;
                  }
                  
                  @-webkit-keyframes animation68 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  @-moz-keyframes animation68 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 70%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @keyframes animation68 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 46%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  .bar:nth-child(69) {
                    -webkit-transform: rotate(207deg);
                    -moz-transform: rotate(207deg);
                    transform: rotate(207deg);
                  }
                  
                  .bar:nth-child(69):after {
                    -webkit-animation: animation69 2s infinite linear alternate;
                    -webkit-animation-delay: -6.9s;
                    -moz-animation: animation69 2s infinite linear alternate;
                    -moz-animation-delay: -6.9s;
                    animation: animation69 2s infinite linear alternate;
                    animation-delay: -6.9s;
                  }
                  
                  @-webkit-keyframes animation69 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 59%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  @-moz-keyframes animation69 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 32%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @keyframes animation69 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 33%;
                    }
                  }
                  .bar:nth-child(70) {
                    -webkit-transform: rotate(210deg);
                    -moz-transform: rotate(210deg);
                    transform: rotate(210deg);
                  }
                  
                  .bar:nth-child(70):after {
                    -webkit-animation: animation70 2s infinite linear alternate;
                    -webkit-animation-delay: -7s;
                    -moz-animation: animation70 2s infinite linear alternate;
                    -moz-animation-delay: -7s;
                    animation: animation70 2s infinite linear alternate;
                    animation-delay: -7s;
                  }
                  
                  @-webkit-keyframes animation70 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 13%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @-moz-keyframes animation70 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 65%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  @keyframes animation70 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  .bar:nth-child(71) {
                    -webkit-transform: rotate(213deg);
                    -moz-transform: rotate(213deg);
                    transform: rotate(213deg);
                  }
                  
                  .bar:nth-child(71):after {
                    -webkit-animation: animation71 2s infinite linear alternate;
                    -webkit-animation-delay: -7.1s;
                    -moz-animation: animation71 2s infinite linear alternate;
                    -moz-animation-delay: -7.1s;
                    animation: animation71 2s infinite linear alternate;
                    animation-delay: -7.1s;
                  }
                  
                  @-webkit-keyframes animation71 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  @-moz-keyframes animation71 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 24%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  @keyframes animation71 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 51%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  .bar:nth-child(72) {
                    -webkit-transform: rotate(216deg);
                    -moz-transform: rotate(216deg);
                    transform: rotate(216deg);
                  }
                  
                  .bar:nth-child(72):after {
                    -webkit-animation: animation72 2s infinite linear alternate;
                    -webkit-animation-delay: -7.2s;
                    -moz-animation: animation72 2s infinite linear alternate;
                    -moz-animation-delay: -7.2s;
                    animation: animation72 2s infinite linear alternate;
                    animation-delay: -7.2s;
                  }
                  
                  @-webkit-keyframes animation72 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 59%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @-moz-keyframes animation72 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @keyframes animation72 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  .bar:nth-child(73) {
                    -webkit-transform: rotate(219deg);
                    -moz-transform: rotate(219deg);
                    transform: rotate(219deg);
                  }
                  
                  .bar:nth-child(73):after {
                    -webkit-animation: animation73 2s infinite linear alternate;
                    -webkit-animation-delay: -7.3s;
                    -moz-animation: animation73 2s infinite linear alternate;
                    -moz-animation-delay: -7.3s;
                    animation: animation73 2s infinite linear alternate;
                    animation-delay: -7.3s;
                  }
                  
                  @-webkit-keyframes animation73 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 20%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @-moz-keyframes animation73 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @keyframes animation73 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  .bar:nth-child(74) {
                    -webkit-transform: rotate(222deg);
                    -moz-transform: rotate(222deg);
                    transform: rotate(222deg);
                  }
                  
                  .bar:nth-child(74):after {
                    -webkit-animation: animation74 2s infinite linear alternate;
                    -webkit-animation-delay: -7.4s;
                    -moz-animation: animation74 2s infinite linear alternate;
                    -moz-animation-delay: -7.4s;
                    animation: animation74 2s infinite linear alternate;
                    animation-delay: -7.4s;
                  }
                  
                  @-webkit-keyframes animation74 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @-moz-keyframes animation74 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  @keyframes animation74 {
                    0% {
                      height: 33%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  .bar:nth-child(75) {
                    -webkit-transform: rotate(225deg);
                    -moz-transform: rotate(225deg);
                    transform: rotate(225deg);
                  }
                  
                  .bar:nth-child(75):after {
                    -webkit-animation: animation75 2s infinite linear alternate;
                    -webkit-animation-delay: -7.5s;
                    -moz-animation: animation75 2s infinite linear alternate;
                    -moz-animation-delay: -7.5s;
                    animation: animation75 2s infinite linear alternate;
                    animation-delay: -7.5s;
                  }
                  
                  @-webkit-keyframes animation75 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  @-moz-keyframes animation75 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @keyframes animation75 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 48%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  .bar:nth-child(76) {
                    -webkit-transform: rotate(228deg);
                    -moz-transform: rotate(228deg);
                    transform: rotate(228deg);
                  }
                  
                  .bar:nth-child(76):after {
                    -webkit-animation: animation76 2s infinite linear alternate;
                    -webkit-animation-delay: -7.6s;
                    -moz-animation: animation76 2s infinite linear alternate;
                    -moz-animation-delay: -7.6s;
                    animation: animation76 2s infinite linear alternate;
                    animation-delay: -7.6s;
                  }
                  
                  @-webkit-keyframes animation76 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 46%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @-moz-keyframes animation76 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  @keyframes animation76 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 40%;
                    }
                  }
                  .bar:nth-child(77) {
                    -webkit-transform: rotate(231deg);
                    -moz-transform: rotate(231deg);
                    transform: rotate(231deg);
                  }
                  
                  .bar:nth-child(77):after {
                    -webkit-animation: animation77 2s infinite linear alternate;
                    -webkit-animation-delay: -7.7s;
                    -moz-animation: animation77 2s infinite linear alternate;
                    -moz-animation-delay: -7.7s;
                    animation: animation77 2s infinite linear alternate;
                    animation-delay: -7.7s;
                  }
                  
                  @-webkit-keyframes animation77 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 44%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  @-moz-keyframes animation77 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  @keyframes animation77 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  .bar:nth-child(78) {
                    -webkit-transform: rotate(234deg);
                    -moz-transform: rotate(234deg);
                    transform: rotate(234deg);
                  }
                  
                  .bar:nth-child(78):after {
                    -webkit-animation: animation78 2s infinite linear alternate;
                    -webkit-animation-delay: -7.8s;
                    -moz-animation: animation78 2s infinite linear alternate;
                    -moz-animation-delay: -7.8s;
                    animation: animation78 2s infinite linear alternate;
                    animation-delay: -7.8s;
                  }
                  
                  @-webkit-keyframes animation78 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @-moz-keyframes animation78 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 51%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  @keyframes animation78 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 15%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  .bar:nth-child(79) {
                    -webkit-transform: rotate(237deg);
                    -moz-transform: rotate(237deg);
                    transform: rotate(237deg);
                  }
                  
                  .bar:nth-child(79):after {
                    -webkit-animation: animation79 2s infinite linear alternate;
                    -webkit-animation-delay: -7.9s;
                    -moz-animation: animation79 2s infinite linear alternate;
                    -moz-animation-delay: -7.9s;
                    animation: animation79 2s infinite linear alternate;
                    animation-delay: -7.9s;
                  }
                  
                  @-webkit-keyframes animation79 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 45%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @-moz-keyframes animation79 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  @keyframes animation79 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  .bar:nth-child(80) {
                    -webkit-transform: rotate(240deg);
                    -moz-transform: rotate(240deg);
                    transform: rotate(240deg);
                  }
                  
                  .bar:nth-child(80):after {
                    -webkit-animation: animation80 2s infinite linear alternate;
                    -webkit-animation-delay: -8s;
                    -moz-animation: animation80 2s infinite linear alternate;
                    -moz-animation-delay: -8s;
                    animation: animation80 2s infinite linear alternate;
                    animation-delay: -8s;
                  }
                  
                  @-webkit-keyframes animation80 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 34%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @-moz-keyframes animation80 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 22%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @keyframes animation80 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 29%;
                    }
                    100% {
                      height: 33%;
                    }
                  }
                  .bar:nth-child(81) {
                    -webkit-transform: rotate(243deg);
                    -moz-transform: rotate(243deg);
                    transform: rotate(243deg);
                  }
                  
                  .bar:nth-child(81):after {
                    -webkit-animation: animation81 2s infinite linear alternate;
                    -webkit-animation-delay: -8.1s;
                    -moz-animation: animation81 2s infinite linear alternate;
                    -moz-animation-delay: -8.1s;
                    animation: animation81 2s infinite linear alternate;
                    animation-delay: -8.1s;
                  }
                  
                  @-webkit-keyframes animation81 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 39%;
                    }
                    100% {
                      height: 17%;
                    }
                  }
                  @-moz-keyframes animation81 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @keyframes animation81 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 51%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  .bar:nth-child(82) {
                    -webkit-transform: rotate(246deg);
                    -moz-transform: rotate(246deg);
                    transform: rotate(246deg);
                  }
                  
                  .bar:nth-child(82):after {
                    -webkit-animation: animation82 2s infinite linear alternate;
                    -webkit-animation-delay: -8.2s;
                    -moz-animation: animation82 2s infinite linear alternate;
                    -moz-animation-delay: -8.2s;
                    animation: animation82 2s infinite linear alternate;
                    animation-delay: -8.2s;
                  }
                  
                  @-webkit-keyframes animation82 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 65%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  @-moz-keyframes animation82 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 52%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @keyframes animation82 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 26%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  .bar:nth-child(83) {
                    -webkit-transform: rotate(249deg);
                    -moz-transform: rotate(249deg);
                    transform: rotate(249deg);
                  }
                  
                  .bar:nth-child(83):after {
                    -webkit-animation: animation83 2s infinite linear alternate;
                    -webkit-animation-delay: -8.3s;
                    -moz-animation: animation83 2s infinite linear alternate;
                    -moz-animation-delay: -8.3s;
                    animation: animation83 2s infinite linear alternate;
                    animation-delay: -8.3s;
                  }
                  
                  @-webkit-keyframes animation83 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 33%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @-moz-keyframes animation83 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 26%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  @keyframes animation83 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 65%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  .bar:nth-child(84) {
                    -webkit-transform: rotate(252deg);
                    -moz-transform: rotate(252deg);
                    transform: rotate(252deg);
                  }
                  
                  .bar:nth-child(84):after {
                    -webkit-animation: animation84 2s infinite linear alternate;
                    -webkit-animation-delay: -8.4s;
                    -moz-animation: animation84 2s infinite linear alternate;
                    -moz-animation-delay: -8.4s;
                    animation: animation84 2s infinite linear alternate;
                    animation-delay: -8.4s;
                  }
                  
                  @-webkit-keyframes animation84 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @-moz-keyframes animation84 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 29%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  @keyframes animation84 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  .bar:nth-child(85) {
                    -webkit-transform: rotate(255deg);
                    -moz-transform: rotate(255deg);
                    transform: rotate(255deg);
                  }
                  
                  .bar:nth-child(85):after {
                    -webkit-animation: animation85 2s infinite linear alternate;
                    -webkit-animation-delay: -8.5s;
                    -moz-animation: animation85 2s infinite linear alternate;
                    -moz-animation-delay: -8.5s;
                    animation: animation85 2s infinite linear alternate;
                    animation-delay: -8.5s;
                  }
                  
                  @-webkit-keyframes animation85 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 42%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  @-moz-keyframes animation85 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 69%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @keyframes animation85 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  .bar:nth-child(86) {
                    -webkit-transform: rotate(258deg);
                    -moz-transform: rotate(258deg);
                    transform: rotate(258deg);
                  }
                  
                  .bar:nth-child(86):after {
                    -webkit-animation: animation86 2s infinite linear alternate;
                    -webkit-animation-delay: -8.6s;
                    -moz-animation: animation86 2s infinite linear alternate;
                    -moz-animation-delay: -8.6s;
                    animation: animation86 2s infinite linear alternate;
                    animation-delay: -8.6s;
                  }
                  
                  @-webkit-keyframes animation86 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 55%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @-moz-keyframes animation86 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 45%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @keyframes animation86 {
                    0% {
                      height: 33%;
                    }
                    50% {
                      height: 54%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  .bar:nth-child(87) {
                    -webkit-transform: rotate(261deg);
                    -moz-transform: rotate(261deg);
                    transform: rotate(261deg);
                  }
                  
                  .bar:nth-child(87):after {
                    -webkit-animation: animation87 2s infinite linear alternate;
                    -webkit-animation-delay: -8.7s;
                    -moz-animation: animation87 2s infinite linear alternate;
                    -moz-animation-delay: -8.7s;
                    animation: animation87 2s infinite linear alternate;
                    animation-delay: -8.7s;
                  }
                  
                  @-webkit-keyframes animation87 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 17%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @-moz-keyframes animation87 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 40%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  @keyframes animation87 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 36%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  .bar:nth-child(88) {
                    -webkit-transform: rotate(264deg);
                    -moz-transform: rotate(264deg);
                    transform: rotate(264deg);
                  }
                  
                  .bar:nth-child(88):after {
                    -webkit-animation: animation88 2s infinite linear alternate;
                    -webkit-animation-delay: -8.8s;
                    -moz-animation: animation88 2s infinite linear alternate;
                    -moz-animation-delay: -8.8s;
                    animation: animation88 2s infinite linear alternate;
                    animation-delay: -8.8s;
                  }
                  
                  @-webkit-keyframes animation88 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 38%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @-moz-keyframes animation88 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 15%;
                    }
                    100% {
                      height: 30%;
                    }
                  }
                  @keyframes animation88 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 20%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  .bar:nth-child(89) {
                    -webkit-transform: rotate(267deg);
                    -moz-transform: rotate(267deg);
                    transform: rotate(267deg);
                  }
                  
                  .bar:nth-child(89):after {
                    -webkit-animation: animation89 2s infinite linear alternate;
                    -webkit-animation-delay: -8.9s;
                    -moz-animation: animation89 2s infinite linear alternate;
                    -moz-animation-delay: -8.9s;
                    animation: animation89 2s infinite linear alternate;
                    animation-delay: -8.9s;
                  }
                  
                  @-webkit-keyframes animation89 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  @-moz-keyframes animation89 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  @keyframes animation89 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 15%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  .bar:nth-child(90) {
                    -webkit-transform: rotate(270deg);
                    -moz-transform: rotate(270deg);
                    transform: rotate(270deg);
                  }
                  
                  .bar:nth-child(90):after {
                    -webkit-animation: animation90 2s infinite linear alternate;
                    -webkit-animation-delay: -9s;
                    -moz-animation: animation90 2s infinite linear alternate;
                    -moz-animation-delay: -9s;
                    animation: animation90 2s infinite linear alternate;
                    animation-delay: -9s;
                  }
                  
                  @-webkit-keyframes animation90 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 18%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @-moz-keyframes animation90 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 52%;
                    }
                    100% {
                      height: 17%;
                    }
                  }
                  @keyframes animation90 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 25%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  .bar:nth-child(91) {
                    -webkit-transform: rotate(273deg);
                    -moz-transform: rotate(273deg);
                    transform: rotate(273deg);
                  }
                  
                  .bar:nth-child(91):after {
                    -webkit-animation: animation91 2s infinite linear alternate;
                    -webkit-animation-delay: -9.1s;
                    -moz-animation: animation91 2s infinite linear alternate;
                    -moz-animation-delay: -9.1s;
                    animation: animation91 2s infinite linear alternate;
                    animation-delay: -9.1s;
                  }
                  
                  @-webkit-keyframes animation91 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 59%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @-moz-keyframes animation91 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 11%;
                    }
                  }
                  @keyframes animation91 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 54%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  .bar:nth-child(92) {
                    -webkit-transform: rotate(276deg);
                    -moz-transform: rotate(276deg);
                    transform: rotate(276deg);
                  }
                  
                  .bar:nth-child(92):after {
                    -webkit-animation: animation92 2s infinite linear alternate;
                    -webkit-animation-delay: -9.2s;
                    -moz-animation: animation92 2s infinite linear alternate;
                    -moz-animation-delay: -9.2s;
                    animation: animation92 2s infinite linear alternate;
                    animation-delay: -9.2s;
                  }
                  
                  @-webkit-keyframes animation92 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @-moz-keyframes animation92 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @keyframes animation92 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  .bar:nth-child(93) {
                    -webkit-transform: rotate(279deg);
                    -moz-transform: rotate(279deg);
                    transform: rotate(279deg);
                  }
                  
                  .bar:nth-child(93):after {
                    -webkit-animation: animation93 2s infinite linear alternate;
                    -webkit-animation-delay: -9.3s;
                    -moz-animation: animation93 2s infinite linear alternate;
                    -moz-animation-delay: -9.3s;
                    animation: animation93 2s infinite linear alternate;
                    animation-delay: -9.3s;
                  }
                  
                  @-webkit-keyframes animation93 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 24%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  @-moz-keyframes animation93 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 47%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  @keyframes animation93 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 56%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  .bar:nth-child(94) {
                    -webkit-transform: rotate(282deg);
                    -moz-transform: rotate(282deg);
                    transform: rotate(282deg);
                  }
                  
                  .bar:nth-child(94):after {
                    -webkit-animation: animation94 2s infinite linear alternate;
                    -webkit-animation-delay: -9.4s;
                    -moz-animation: animation94 2s infinite linear alternate;
                    -moz-animation-delay: -9.4s;
                    animation: animation94 2s infinite linear alternate;
                    animation-delay: -9.4s;
                  }
                  
                  @-webkit-keyframes animation94 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 19%;
                    }
                    100% {
                      height: 33%;
                    }
                  }
                  @-moz-keyframes animation94 {
                    0% {
                      height: 21%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  @keyframes animation94 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  .bar:nth-child(95) {
                    -webkit-transform: rotate(285deg);
                    -moz-transform: rotate(285deg);
                    transform: rotate(285deg);
                  }
                  
                  .bar:nth-child(95):after {
                    -webkit-animation: animation95 2s infinite linear alternate;
                    -webkit-animation-delay: -9.5s;
                    -moz-animation: animation95 2s infinite linear alternate;
                    -moz-animation-delay: -9.5s;
                    animation: animation95 2s infinite linear alternate;
                    animation-delay: -9.5s;
                  }
                  
                  @-webkit-keyframes animation95 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 22%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @-moz-keyframes animation95 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 17%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @keyframes animation95 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  .bar:nth-child(96) {
                    -webkit-transform: rotate(288deg);
                    -moz-transform: rotate(288deg);
                    transform: rotate(288deg);
                  }
                  
                  .bar:nth-child(96):after {
                    -webkit-animation: animation96 2s infinite linear alternate;
                    -webkit-animation-delay: -9.6s;
                    -moz-animation: animation96 2s infinite linear alternate;
                    -moz-animation-delay: -9.6s;
                    animation: animation96 2s infinite linear alternate;
                    animation-delay: -9.6s;
                  }
                  
                  @-webkit-keyframes animation96 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 23%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @-moz-keyframes animation96 {
                    0% {
                      height: 19%;
                    }
                    50% {
                      height: 53%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  @keyframes animation96 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 38%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  .bar:nth-child(97) {
                    -webkit-transform: rotate(291deg);
                    -moz-transform: rotate(291deg);
                    transform: rotate(291deg);
                  }
                  
                  .bar:nth-child(97):after {
                    -webkit-animation: animation97 2s infinite linear alternate;
                    -webkit-animation-delay: -9.7s;
                    -moz-animation: animation97 2s infinite linear alternate;
                    -moz-animation-delay: -9.7s;
                    animation: animation97 2s infinite linear alternate;
                    animation-delay: -9.7s;
                  }
                  
                  @-webkit-keyframes animation97 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 50%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  @-moz-keyframes animation97 {
                    0% {
                      height: 36%;
                    }
                    50% {
                      height: 26%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @keyframes animation97 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  .bar:nth-child(98) {
                    -webkit-transform: rotate(294deg);
                    -moz-transform: rotate(294deg);
                    transform: rotate(294deg);
                  }
                  
                  .bar:nth-child(98):after {
                    -webkit-animation: animation98 2s infinite linear alternate;
                    -webkit-animation-delay: -9.8s;
                    -moz-animation: animation98 2s infinite linear alternate;
                    -moz-animation-delay: -9.8s;
                    animation: animation98 2s infinite linear alternate;
                    animation-delay: -9.8s;
                  }
                  
                  @-webkit-keyframes animation98 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  @-moz-keyframes animation98 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  @keyframes animation98 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 52%;
                    }
                    100% {
                      height: 17%;
                    }
                  }
                  .bar:nth-child(99) {
                    -webkit-transform: rotate(297deg);
                    -moz-transform: rotate(297deg);
                    transform: rotate(297deg);
                  }
                  
                  .bar:nth-child(99):after {
                    -webkit-animation: animation99 2s infinite linear alternate;
                    -webkit-animation-delay: -9.9s;
                    -moz-animation: animation99 2s infinite linear alternate;
                    -moz-animation-delay: -9.9s;
                    animation: animation99 2s infinite linear alternate;
                    animation-delay: -9.9s;
                  }
                  
                  @-webkit-keyframes animation99 {
                    0% {
                      height: 21%;
                    }
                    50% {
                      height: 54%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @-moz-keyframes animation99 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  @keyframes animation99 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 39%;
                    }
                    100% {
                      height: 32%;
                    }
                  }
                  .bar:nth-child(100) {
                    -webkit-transform: rotate(300deg);
                    -moz-transform: rotate(300deg);
                    transform: rotate(300deg);
                  }
                  
                  .bar:nth-child(100):after {
                    -webkit-animation: animation100 2s infinite linear alternate;
                    -webkit-animation-delay: -10s;
                    -moz-animation: animation100 2s infinite linear alternate;
                    -moz-animation-delay: -10s;
                    animation: animation100 2s infinite linear alternate;
                    animation-delay: -10s;
                  }
                  
                  @-webkit-keyframes animation100 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 18%;
                    }
                  }
                  @-moz-keyframes animation100 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 57%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  @keyframes animation100 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 21%;
                    }
                  }
                  .bar:nth-child(101) {
                    -webkit-transform: rotate(303deg);
                    -moz-transform: rotate(303deg);
                    transform: rotate(303deg);
                  }
                  
                  .bar:nth-child(101):after {
                    -webkit-animation: animation101 2s infinite linear alternate;
                    -webkit-animation-delay: -10.1s;
                    -moz-animation: animation101 2s infinite linear alternate;
                    -moz-animation-delay: -10.1s;
                    animation: animation101 2s infinite linear alternate;
                    animation-delay: -10.1s;
                  }
                  
                  @-webkit-keyframes animation101 {
                    0% {
                      height: 33%;
                    }
                    50% {
                      height: 42%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @-moz-keyframes animation101 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 21%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @keyframes animation101 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  .bar:nth-child(102) {
                    -webkit-transform: rotate(306deg);
                    -moz-transform: rotate(306deg);
                    transform: rotate(306deg);
                  }
                  
                  .bar:nth-child(102):after {
                    -webkit-animation: animation102 2s infinite linear alternate;
                    -webkit-animation-delay: -10.2s;
                    -moz-animation: animation102 2s infinite linear alternate;
                    -moz-animation-delay: -10.2s;
                    animation: animation102 2s infinite linear alternate;
                    animation-delay: -10.2s;
                  }
                  
                  @-webkit-keyframes animation102 {
                    0% {
                      height: 31%;
                    }
                    50% {
                      height: 52%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @-moz-keyframes animation102 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 30%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  @keyframes animation102 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  .bar:nth-child(103) {
                    -webkit-transform: rotate(309deg);
                    -moz-transform: rotate(309deg);
                    transform: rotate(309deg);
                  }
                  
                  .bar:nth-child(103):after {
                    -webkit-animation: animation103 2s infinite linear alternate;
                    -webkit-animation-delay: -10.3s;
                    -moz-animation: animation103 2s infinite linear alternate;
                    -moz-animation-delay: -10.3s;
                    animation: animation103 2s infinite linear alternate;
                    animation-delay: -10.3s;
                  }
                  
                  @-webkit-keyframes animation103 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  @-moz-keyframes animation103 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 16%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @keyframes animation103 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 70%;
                    }
                    100% {
                      height: 33%;
                    }
                  }
                  .bar:nth-child(104) {
                    -webkit-transform: rotate(312deg);
                    -moz-transform: rotate(312deg);
                    transform: rotate(312deg);
                  }
                  
                  .bar:nth-child(104):after {
                    -webkit-animation: animation104 2s infinite linear alternate;
                    -webkit-animation-delay: -10.4s;
                    -moz-animation: animation104 2s infinite linear alternate;
                    -moz-animation-delay: -10.4s;
                    animation: animation104 2s infinite linear alternate;
                    animation-delay: -10.4s;
                  }
                  
                  @-webkit-keyframes animation104 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 67%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  @-moz-keyframes animation104 {
                    0% {
                      height: 19%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @keyframes animation104 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  .bar:nth-child(105) {
                    -webkit-transform: rotate(315deg);
                    -moz-transform: rotate(315deg);
                    transform: rotate(315deg);
                  }
                  
                  .bar:nth-child(105):after {
                    -webkit-animation: animation105 2s infinite linear alternate;
                    -webkit-animation-delay: -10.5s;
                    -moz-animation: animation105 2s infinite linear alternate;
                    -moz-animation-delay: -10.5s;
                    animation: animation105 2s infinite linear alternate;
                    animation-delay: -10.5s;
                  }
                  
                  @-webkit-keyframes animation105 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 61%;
                    }
                    100% {
                      height: 17%;
                    }
                  }
                  @-moz-keyframes animation105 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  @keyframes animation105 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 51%;
                    }
                    100% {
                      height: 27%;
                    }
                  }
                  .bar:nth-child(106) {
                    -webkit-transform: rotate(318deg);
                    -moz-transform: rotate(318deg);
                    transform: rotate(318deg);
                  }
                  
                  .bar:nth-child(106):after {
                    -webkit-animation: animation106 2s infinite linear alternate;
                    -webkit-animation-delay: -10.6s;
                    -moz-animation: animation106 2s infinite linear alternate;
                    -moz-animation-delay: -10.6s;
                    animation: animation106 2s infinite linear alternate;
                    animation-delay: -10.6s;
                  }
                  
                  @-webkit-keyframes animation106 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @-moz-keyframes animation106 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 39%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @keyframes animation106 {
                    0% {
                      height: 19%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  .bar:nth-child(107) {
                    -webkit-transform: rotate(321deg);
                    -moz-transform: rotate(321deg);
                    transform: rotate(321deg);
                  }
                  
                  .bar:nth-child(107):after {
                    -webkit-animation: animation107 2s infinite linear alternate;
                    -webkit-animation-delay: -10.7s;
                    -moz-animation: animation107 2s infinite linear alternate;
                    -moz-animation-delay: -10.7s;
                    animation: animation107 2s infinite linear alternate;
                    animation-delay: -10.7s;
                  }
                  
                  @-webkit-keyframes animation107 {
                    0% {
                      height: 30%;
                    }
                    50% {
                      height: 58%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @-moz-keyframes animation107 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @keyframes animation107 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 22%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  .bar:nth-child(108) {
                    -webkit-transform: rotate(324deg);
                    -moz-transform: rotate(324deg);
                    transform: rotate(324deg);
                  }
                  
                  .bar:nth-child(108):after {
                    -webkit-animation: animation108 2s infinite linear alternate;
                    -webkit-animation-delay: -10.8s;
                    -moz-animation: animation108 2s infinite linear alternate;
                    -moz-animation-delay: -10.8s;
                    animation: animation108 2s infinite linear alternate;
                    animation-delay: -10.8s;
                  }
                  
                  @-webkit-keyframes animation108 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  @-moz-keyframes animation108 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 13%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  @keyframes animation108 {
                    0% {
                      height: 23%;
                    }
                    50% {
                      height: 15%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  .bar:nth-child(109) {
                    -webkit-transform: rotate(327deg);
                    -moz-transform: rotate(327deg);
                    transform: rotate(327deg);
                  }
                  
                  .bar:nth-child(109):after {
                    -webkit-animation: animation109 2s infinite linear alternate;
                    -webkit-animation-delay: -10.9s;
                    -moz-animation: animation109 2s infinite linear alternate;
                    -moz-animation-delay: -10.9s;
                    animation: animation109 2s infinite linear alternate;
                    animation-delay: -10.9s;
                  }
                  
                  @-webkit-keyframes animation109 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 14%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  @-moz-keyframes animation109 {
                    0% {
                      height: 40%;
                    }
                    50% {
                      height: 54%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @keyframes animation109 {
                    0% {
                      height: 17%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 14%;
                    }
                  }
                  .bar:nth-child(110) {
                    -webkit-transform: rotate(330deg);
                    -moz-transform: rotate(330deg);
                    transform: rotate(330deg);
                  }
                  
                  .bar:nth-child(110):after {
                    -webkit-animation: animation110 2s infinite linear alternate;
                    -webkit-animation-delay: -11s;
                    -moz-animation: animation110 2s infinite linear alternate;
                    -moz-animation-delay: -11s;
                    animation: animation110 2s infinite linear alternate;
                    animation-delay: -11s;
                  }
                  
                  @-webkit-keyframes animation110 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 56%;
                    }
                    100% {
                      height: 22%;
                    }
                  }
                  @-moz-keyframes animation110 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 20%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  @keyframes animation110 {
                    0% {
                      height: 22%;
                    }
                    50% {
                      height: 56%;
                    }
                    100% {
                      height: 38%;
                    }
                  }
                  .bar:nth-child(111) {
                    -webkit-transform: rotate(333deg);
                    -moz-transform: rotate(333deg);
                    transform: rotate(333deg);
                  }
                  
                  .bar:nth-child(111):after {
                    -webkit-animation: animation111 2s infinite linear alternate;
                    -webkit-animation-delay: -11.1s;
                    -moz-animation: animation111 2s infinite linear alternate;
                    -moz-animation-delay: -11.1s;
                    animation: animation111 2s infinite linear alternate;
                    animation-delay: -11.1s;
                  }
                  
                  @-webkit-keyframes animation111 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 59%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @-moz-keyframes animation111 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 34%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @keyframes animation111 {
                    0% {
                      height: 15%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 33%;
                    }
                  }
                  .bar:nth-child(112) {
                    -webkit-transform: rotate(336deg);
                    -moz-transform: rotate(336deg);
                    transform: rotate(336deg);
                  }
                  
                  .bar:nth-child(112):after {
                    -webkit-animation: animation112 2s infinite linear alternate;
                    -webkit-animation-delay: -11.2s;
                    -moz-animation: animation112 2s infinite linear alternate;
                    -moz-animation-delay: -11.2s;
                    animation: animation112 2s infinite linear alternate;
                    animation-delay: -11.2s;
                  }
                  
                  @-webkit-keyframes animation112 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 28%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @-moz-keyframes animation112 {
                    0% {
                      height: 39%;
                    }
                    50% {
                      height: 70%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @keyframes animation112 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 27%;
                    }
                    100% {
                      height: 29%;
                    }
                  }
                  .bar:nth-child(113) {
                    -webkit-transform: rotate(339deg);
                    -moz-transform: rotate(339deg);
                    transform: rotate(339deg);
                  }
                  
                  .bar:nth-child(113):after {
                    -webkit-animation: animation113 2s infinite linear alternate;
                    -webkit-animation-delay: -11.3s;
                    -moz-animation: animation113 2s infinite linear alternate;
                    -moz-animation-delay: -11.3s;
                    animation: animation113 2s infinite linear alternate;
                    animation-delay: -11.3s;
                  }
                  
                  @-webkit-keyframes animation113 {
                    0% {
                      height: 11%;
                    }
                    50% {
                      height: 31%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @-moz-keyframes animation113 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 36%;
                    }
                    100% {
                      height: 26%;
                    }
                  }
                  @keyframes animation113 {
                    0% {
                      height: 27%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 19%;
                    }
                  }
                  .bar:nth-child(114) {
                    -webkit-transform: rotate(342deg);
                    -moz-transform: rotate(342deg);
                    transform: rotate(342deg);
                  }
                  
                  .bar:nth-child(114):after {
                    -webkit-animation: animation114 2s infinite linear alternate;
                    -webkit-animation-delay: -11.4s;
                    -moz-animation: animation114 2s infinite linear alternate;
                    -moz-animation-delay: -11.4s;
                    animation: animation114 2s infinite linear alternate;
                    animation-delay: -11.4s;
                  }
                  
                  @-webkit-keyframes animation114 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 56%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  @-moz-keyframes animation114 {
                    0% {
                      height: 26%;
                    }
                    50% {
                      height: 42%;
                    }
                    100% {
                      height: 31%;
                    }
                  }
                  @keyframes animation114 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 30%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  .bar:nth-child(115) {
                    -webkit-transform: rotate(345deg);
                    -moz-transform: rotate(345deg);
                    transform: rotate(345deg);
                  }
                  
                  .bar:nth-child(115):after {
                    -webkit-animation: animation115 2s infinite linear alternate;
                    -webkit-animation-delay: -11.5s;
                    -moz-animation: animation115 2s infinite linear alternate;
                    -moz-animation-delay: -11.5s;
                    animation: animation115 2s infinite linear alternate;
                    animation-delay: -11.5s;
                  }
                  
                  @-webkit-keyframes animation115 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                  @-moz-keyframes animation115 {
                    0% {
                      height: 32%;
                    }
                    50% {
                      height: 22%;
                    }
                    100% {
                      height: 15%;
                    }
                  }
                  @keyframes animation115 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 22%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  .bar:nth-child(116) {
                    -webkit-transform: rotate(348deg);
                    -moz-transform: rotate(348deg);
                    transform: rotate(348deg);
                  }
                  
                  .bar:nth-child(116):after {
                    -webkit-animation: animation116 2s infinite linear alternate;
                    -webkit-animation-delay: -11.6s;
                    -moz-animation: animation116 2s infinite linear alternate;
                    -moz-animation-delay: -11.6s;
                    animation: animation116 2s infinite linear alternate;
                    animation-delay: -11.6s;
                  }
                  
                  @-webkit-keyframes animation116 {
                    0% {
                      height: 13%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 25%;
                    }
                  }
                  @-moz-keyframes animation116 {
                    0% {
                      height: 12%;
                    }
                    50% {
                      height: 57%;
                    }
                    100% {
                      height: 13%;
                    }
                  }
                  @keyframes animation116 {
                    0% {
                      height: 25%;
                    }
                    50% {
                      height: 24%;
                    }
                    100% {
                      height: 37%;
                    }
                  }
                  .bar:nth-child(117) {
                    -webkit-transform: rotate(351deg);
                    -moz-transform: rotate(351deg);
                    transform: rotate(351deg);
                  }
                  
                  .bar:nth-child(117):after {
                    -webkit-animation: animation117 2s infinite linear alternate;
                    -webkit-animation-delay: -11.7s;
                    -moz-animation: animation117 2s infinite linear alternate;
                    -moz-animation-delay: -11.7s;
                    animation: animation117 2s infinite linear alternate;
                    animation-delay: -11.7s;
                  }
                  
                  @-webkit-keyframes animation117 {
                    0% {
                      height: 38%;
                    }
                    50% {
                      height: 47%;
                    }
                    100% {
                      height: 12%;
                    }
                  }
                  @-moz-keyframes animation117 {
                    0% {
                      height: 28%;
                    }
                    50% {
                      height: 41%;
                    }
                    100% {
                      height: 20%;
                    }
                  }
                  @keyframes animation117 {
                    0% {
                      height: 35%;
                    }
                    50% {
                      height: 63%;
                    }
                    100% {
                      height: 34%;
                    }
                  }
                  .bar:nth-child(118) {
                    -webkit-transform: rotate(354deg);
                    -moz-transform: rotate(354deg);
                    transform: rotate(354deg);
                  }
                  
                  .bar:nth-child(118):after {
                    -webkit-animation: animation118 2s infinite linear alternate;
                    -webkit-animation-delay: -11.8s;
                    -moz-animation: animation118 2s infinite linear alternate;
                    -moz-animation-delay: -11.8s;
                    animation: animation118 2s infinite linear alternate;
                    animation-delay: -11.8s;
                  }
                  
                  @-webkit-keyframes animation118 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 49%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  @-moz-keyframes animation118 {
                    0% {
                      height: 37%;
                    }
                    50% {
                      height: 43%;
                    }
                    100% {
                      height: 16%;
                    }
                  }
                  @keyframes animation118 {
                    0% {
                      height: 14%;
                    }
                    50% {
                      height: 64%;
                    }
                    100% {
                      height: 39%;
                    }
                  }
                  .bar:nth-child(119) {
                    -webkit-transform: rotate(357deg);
                    -moz-transform: rotate(357deg);
                    transform: rotate(357deg);
                  }
                  
                  .bar:nth-child(119):after {
                    -webkit-animation: animation119 2s infinite linear alternate;
                    -webkit-animation-delay: -11.9s;
                    -moz-animation: animation119 2s infinite linear alternate;
                    -moz-animation-delay: -11.9s;
                    animation: animation119 2s infinite linear alternate;
                    animation-delay: -11.9s;
                  }
                  
                  @-webkit-keyframes animation119 {
                    0% {
                      height: 16%;
                    }
                    50% {
                      height: 37%;
                    }
                    100% {
                      height: 23%;
                    }
                  }
                  @-moz-keyframes animation119 {
                    0% {
                      height: 34%;
                    }
                    50% {
                      height: 11%;
                    }
                    100% {
                      height: 33%;
                    }
                  }
                  @keyframes animation119 {
                    0% {
                      height: 20%;
                    }
                    50% {
                      height: 68%;
                    }
                    100% {
                      height: 28%;
                    }
                  }
                  .bar:nth-child(120) {
                    -webkit-transform: rotate(360deg);
                    -moz-transform: rotate(360deg);
                    transform: rotate(360deg);
                  }
                  
                  .bar:nth-child(120):after {
                    -webkit-animation: animation120 2s infinite linear alternate;
                    -webkit-animation-delay: -12s;
                    -moz-animation: animation120 2s infinite linear alternate;
                    -moz-animation-delay: -12s;
                    animation: animation120 2s infinite linear alternate;
                    animation-delay: -12s;
                  }
                  
                  @-webkit-keyframes animation120 {
                    0% {
                      height: 24%;
                    }
                    50% {
                      height: 52%;
                    }
                    100% {
                      height: 24%;
                    }
                  }
                  @-moz-keyframes animation120 {
                    0% {
                      height: 29%;
                    }
                    50% {
                      height: 49%;
                    }
                    100% {
                      height: 36%;
                    }
                  }
                  @keyframes animation120 {
                    0% {
                      height: 18%;
                    }
                    50% {
                      height: 54%;
                    }
                    100% {
                      height: 35%;
                    }
                  }
                
                `}
            </style>
            <div className='container'>
                <div className='container-inner'>
                <div className='bars'>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                    <div className='bar'></div>
                </div>
                </div>
            </div>
        </>
      </CSSTransition>
        {showButton && (
            <button onClick={audioUrl ? handlePlayButtonClick:handleButtonClick } className={isRecording 
              ? 'recording-button' 
              : isGenerating 
              ? 'generate' 
              : 'not-recording'}>
              {isRecording ? '' : ''}
            </button>
          )}
        </div>
      
      <div className="StatusButton">
        {statusButton && <>
          <CSSTransition
            in={inProp}
            timeout={500}
            classNames="text"
          >
            <h2 className="show">{statusButton}</h2>
          </CSSTransition>
            </>}
      </div>
      {audioUrl && (
          <CSSTransition
          in={inPlay}
          timeout={500}
          >
              <h2 className='send-telegram' onClick={sendConvertedFile}>
                Отправить в Telegram
              </h2>
          </CSSTransition>
          )}
      
      
    </div>
  );
}

export default App;