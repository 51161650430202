import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useState, useEffect, useContext } from 'react';
import { TwaAnalyticsProvider } from '@tonsolutions/analytics-react';

import MainLayout from "./layouts/MainLayout/MainLayout";
import MainPage from "./pages/MainPage";

import { CreatorContext, TgContext } from "./settings/context";
import DAS_endpoints from "./settings/DAS_endpoints";
import ROUTES_LIST from "./settings/routes";
import DAS_HTTP from "./tools/DAS_HTTP";

export default function RouterPath() {

    const [stage, setStage] = useState<number>(0);
    const [creatorInfo, setCreatorInfo] = useState<any>({
        photo: "",
        name: "",
        type_user: "",
        link: "",
        description: "",
        example_video: [],
        example_photo: [],
        example_circle: [],
        order: null
    });

    const tgApp = useWebApp();

    const getCreatorData = async (id: string) => {
        await DAS_HTTP.request({
            url: DAS_endpoints.GET_CREATOR_DATA,
            method: "POST",
            data: { id: id, user_id: tgApp?.initDataUnsafe?.user?.id ?? null },
            getData: true
        }).then((res: any) => {
            if (res) {
                setCreatorInfo(res);
                setStage(1);
            }
        }).catch((e: any) => {
            console.error(e);
            setStage(6);
        });
    }

    useEffect(() => {
        if (tgApp) {
            tgApp.expand();
            // if (tgApp?.writeAccessRequested !== "allowed"){
            //     tgApp?.requestWriteAccess();
            // }
        }
    }, [tgApp]);

    useEffect(() => {
        if (tgApp && tgApp?.initDataUnsafe?.start_param) {
            getCreatorData(tgApp?.initDataUnsafe?.start_param);
        }
        else{
            setStage(6);
        }
    }, [tgApp]);

    console.log(tgApp);

    return (
        <TgContext.Provider value={{ tgApp }}>
            <TwaAnalyticsProvider apiKey="7fee6341-0d58-4d3d-8317-51f25c787df3" projectId="9236e5ba-0d54-4df9-af70-747fd0f7f961" appName="Direct TWA">
                <CreatorContext.Provider value={{ creatorInfo }}>
                    <Router>
                        <Routes>
                            <Route path={ROUTES_LIST.index} element={<MainLayout />}>
                                <Route path={ROUTES_LIST.index} element={<MainPage stage={stage} setStage={setStage}/>} />
                            </Route>
                        </Routes>
                    </Router>
                </CreatorContext.Provider>
            </TwaAnalyticsProvider>
        </TgContext.Provider>
    )
}
