import { useTWAEvent } from '@tonsolutions/analytics-react';
import { useState, useEffect, useContext, useRef, Dispatch, SetStateAction, Component } from 'react';
import { useFormik } from 'formik';
import Iframe from 'react-iframe'
import * as yup from "yup";
import useSound from 'use-sound';
import PaymentConfirmed from '../PaymentConfirmed/PaymentConfirmed';
import CirclePreloader from '../../../UI/Preloader/CirclePreloader';
import ContentExample from '../ContentExample/ContentExample';
import PaymentButton from '../PaymentButton/PaymentButton';
import HeadingInfo from '../HeadingInfo/HeadingInfo';
import OrderDetail from '../OrderDetail/OrderDetail';
import TotalPrice from '../TotalPrice/TotalPrice';
import Button from '../../../UI/Button/Button';
import TopicType from '../TopicType/TopicType';
import InfoBlock from '../InfoBlock/InfoBlock';
import Heading from '../Heading/Heading';
import Order from '../Order/Order';
import Task from '../Task/Task';
import sound from '../../../images/ai_voice_response.ogg'
import { CreatorContext, TgContext } from '../../../settings/context';
import { SERVER_URL_IMAGE } from '../../../settings/config';
import DAS_endpoints from '../../../settings/DAS_endpoints';
import Functions from '../../../tools/Functions';
import DAS_HTTP from '../../../tools/DAS_HTTP';
import App from "./App.js";
import styles from "./MainModule.module.scss";

interface MainModuleInt {
    stage: number,
    setStage: Dispatch<SetStateAction<number>>
}



export default function MainModule({ stage, setStage }: MainModuleInt) {
    const [selectedTheme, setSelectedTheme] = useState<number | null>(0); //radioGroup
    const [selectedTopicType, setSelectedTopicType] = useState<number | null>(0); //radioGroup
    const [paymentSpeed, setPaymentSpeed] = useState<number>(0);
    const [contentType, setContentType] = useState<number>(0);
    const [paymentLink, setPaymentLink] = useState<string>("");
    const [coins, setCoins] = useState<number>(0); 
    const submitFormRef = useRef(false);
    let audio = new Audio(sound);
    // const builder = useTWAEvent();

    const { creatorInfo } = useContext(CreatorContext);
    const { tgApp } = useContext(TgContext);
    useEffect(() => {
        const fetchCoins = async () => {
            if (tgApp?.initDataUnsafe?.user?.id) {
                try {
                    const response = await fetch(`https://ktulchu.pskovhack.ru/get_coins?user_id=${tgApp.initDataUnsafe.user.id}`);
                    const data = await response.json();
                    if (response.ok) {
                        setCoins(data.coins);
                    } else {
                        console.error("Failed to fetch coins:", data.error);
                    }
                } catch (error) {
                    console.error("Error fetching coins:", error);
                }
            }
        };
        fetchCoins();
    }, [tgApp?.initDataUnsafe?.user?.id]);
    const form = useFormik({
        initialValues: {
            videoDuration: "",
            type_of_content: null, //RadioGroup
            description: "",
            name_to: "",
            name_for: `${tgApp?.initDataUnsafe?.user?.first_name ?? ""} ${tgApp?.initDataUnsafe?.user?.last_name ?? ""}` ?? ""
        },
        validationSchema: yup.object().shape({
            description: yup.string().max(255, "max")
        }),
        onSubmit: async (values) => {
            if (submitFormRef.current === false) {
                submitFormRef.current = true;
                await DAS_HTTP.request({
                    url: DAS_endpoints.CREATE_ORDER,
                    method: "POST",
                    data: {
                        user_for: tgApp?.initDataUnsafe?.start_param,
                        user_to: tgApp?.initDataUnsafe?.user?.id,
                        theme: creatorInfo.order[Number(selectedTheme)].id,
                        //@ts-ignore
                        topic_type: creatorInfo.order[selectedTheme].type_order[Number(selectedTopicType)].id,
                        delivery_type: creatorInfo.order[selectedTheme].type_order[Number(selectedTopicType)].cost[paymentSpeed].id,
                        description: values.description,
                        name_for: values.name_for
                    },
                    getData: true
                }).then((res: any) => {
                    submitFormRef.current = false;
                    // getPaymentInfo();
                    // setPaymentLink(res.url);
                    tgApp.openTelegramLink(res.result);
                    // window.location.assign(res.url);
                    // stageHandler(7);
                }).catch((e: any) => {
                    submitFormRef.current = false;
                    console.error(e);
                });
            }
        }
    });

    return (
        <section className={(Number(stage) === 3) ? styles["main-module__payment"] : ""}>
            {/* preloader screen */}
            {(stage === 0) && (
                <div className="grid-one center padding">
                    <CirclePreloader />
                </div>
            )}
            
            <App user_id={tgApp?.initDataUnsafe?.user?.id} coins={coins} setCoins={setCoins}/>
        </section>
    )
}
